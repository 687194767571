.cms-input-text {
  &.rte-container {
    margin-top: 14px;
    label.quill-label {
      font-family: "Roboto", sans-serif;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.4);
      margin-top: 6px;
      position: absolute;
      margin-left: 10px;
    }
    .quill {
      .ql-container.ql-snow {
        border: 0;
      }
      .ql-toolbar.ql-snow {
        padding: 0;
        border: 0;
        padding-top: 27px;
        border-bottom: 1px solid #ccc;
        .ql-picker-options {
          z-index: 9;
        }
      }
      border-bottom: 1px solid rgb(224, 224, 224);
      color: rgba(0, 0, 0, 0.87);
      .ql-editor {
        padding-bottom: 4px;
        padding-left: 0;
      }
      .ql-bubble .ql-tooltip {
        z-index: 9;
      }
      .ql-spanblock:after {
        content: "<sb/>";
      }
    }
    .ql-snow {
      .default-buttons {
        display: flex;
      }
      .extended-buttons {
        display: flex;
      }
      .ql-picker-label::before {
        margin-right: 16px;
      }
      .ql-picker.ql-class {
        .ql-picker-label::before {
          content: "Class";
        }
        .ql-picker-options {
          .ql-picker-item[data-value="display"]::before {
            content: "Display";
          }
          .ql-picker-item[data-value="title"]::before {
            content: "Title";
          }
          .ql-picker-item[data-value="subtitle"]::before {
            content: "Subtitle";
          }
          .ql-picker-item[data-value="subheading"]::before {
            content: "Subheading";
          }
          .ql-picker-item[data-value="body"]::before {
            content: "Body";
          }
          .ql-picker-item[data-value="quote"]::before {
            content: "Quote";
          }
          .ql-picker-item[data-value="quote_attribution"]::before {
            content: "Quote Attribution";
          }
          .ql-picker-item[data-value="caption_title"]::before {
            content: "Caption Title";
          }
          .ql-picker-item[data-value="caption"]::before {
            content: "Caption";
          }
          .ql-picker-item[data-value="credit"]::before {
            content: "Credit";
          }
          .ql-picker-item[data-value="overline"]::before {
            content: "Overline";
          }
        }
      }
      .ql-picker.ql-scale {
        .ql-picker-label::before {
          content: "Scale";
        }
        .ql-picker-options {
          .ql-picker-item[data-value="primary"]::before {
            content: "Primary";
          }
          .ql-picker-item[data-value="secondary"]::before {
            content: "Secondary";
          }
          .ql-picker-item[data-value="extra_large"]::before {
            content: "Extra Large";
          }
          .ql-picker-item[data-value="large"]::before {
            content: "Large";
          }
          .ql-picker-item[data-value="medium"]::before {
            content: "Medium";
          }
          .ql-picker-item[data-value="small"]::before {
            content: "Small";
          }
          .ql-picker-item[data-value="extra_small"]::before {
            content: "Extra Small";
          }
        }
      }
    }
  }

  &.bubble {
    .quill {
      padding-top: 12px;
      .ql-editor {
        font-size: 13px;
      }
    }
  }

  &.plain {
    position: relative;
    .quill {
      background-color: rgb(246, 246, 246);
      border-radius: 4px;
      border: 0;
      .ql-editor {
        padding: 10px;
      }
    }
  }
}
