@import "~styles/variables";

.feedback-browser-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: $background-light-1;
  color: $color-dark-primary;
  .container-body{
    overflow: auto;
  }
  .filters-bar-container {
    height: 100%;
    padding-top: 14px;
    text-align: right;
  }
}