@import "~styles/variables";

.user-list-browser {
  .filters-bar-container{
    height: 100%;
    padding-top: 14px;
    text-align: right;
  }
  .container-body{
    overflow: auto;
  }

  .content-container > div > div.container-body > div > div > div:nth-child(1) > table > thead > tr > th:nth-child(1) > div {
    visibility: hidden
  }
}