@import "~styles/variables";

:export {
  iconColor: $color-light-secondary;
}

.main-sidebar {
  padding: 0;
  margin: 0;
  height: 100vh;
  float: left;
  position: relative;
  z-index: 1000;
  //display: table;/*not extactly sure about use of this...*/
  background: $background-dark-3;
  color: $background-light-3;
  box-shadow: $color-dark-secondary 4px 0 8px;

  &.expanded{
    width: $main-sidebar-expanded-width;
  }
  &.collapsed{
    width: $main-sidebar-collapsed-width;
  }

  .logo-container{
    height: $header-height;
    width: 100%;
    padding: 28px;
    box-sizing: border-box;
    a {
      display: block;
      text-decoration-line: none;
      color: inherit;
    }
    h1{
      line-height:1.1;
      margin-top: 0;
    }
  }

  .site-name-container{
    padding: 0 ($element-baseline-grid * 3);
  }

  .bottom-navigation{
    position: absolute;
    bottom: ($element-baseline-grid);
    padding: 0;
  }

  .sidebar-toggle{
    margin-left: 6px;
    margin-top: ($element-baseline-grid * 2);
    border-top: 1px solid $background-dark-1;
    width: 88%;
    position: relative;
    .toggle-label {
      position: absolute;
      top: 22px;
      left: 58px;
      color: $color-light-secondary;
    }
    &:hover {
      cursor: pointer;
      .toggle-label {
        color: $color-accent-2;
      }
      svg {
        fill: $color-accent-2 !important;
      }
    }
  }
  .menu-item{
    button{
      outline: 0
    }
  }
}