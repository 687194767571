.multiform-container {

  .cms-input-text {
    margin: 6px 0 !important;
  }

  td {
    white-space: normal !important;
  }

  // Needed this style to prevent the popup (bubble) Quill RTE
  // from being cut off. We avoided this problem by switching 
  // to rendering the toolbar above the input field.
  // > div:nth-child(2) > div:nth-child(2) {
  //   height: inherit;
  //   overflow: visible !important
  // }
}