@import "~styles/variables";
@import "~styles/mixins";

:export {
  contentContainerBack: $background-light-2;
  captionHeight: $card-caption-height;
  minWidthCaption: $card-browser-breakpoint-caption;
  minCardMargin: $card-browser-minimum-card-margin;
}

.category-editor {
  position: absolute;
  width: 100%;
  height: 100%;
  .content-container {
    .header-container {
      box-shadow: rgba(150, 150, 150, 0.8) 0 2px 6px !important;
      .header-title {
        width: calc(100% - 650px);
      }
      .header-content {
        width: 550px;
      }
    }
  }

  .editor-container {
    // width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    .tab-form-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;
      .tab-column {
        position: relative;
        float: left;
        box-sizing: border-box;
        width: 28%;
        height: 100%;
        overflow: visible;
        .cms-list {
          margin-bottom: 30px;
        }
        .list-group-header {
          h2 {
            font-size: 12px;
            color: black;
            font-weight: bold;
            font-family: Roboto, sans-serif;
          }
        }
      }
      .column-right-border {
        border-right: 1px solid #ccc;
      }
    }
  }

  .editor-topbar {
    .category-navigation-component {
      float: right;
      color: $color-dark-primary;
      .category-numbers-container {
        float: left;
        padding-top: 26px;
      }
      .empty-arrow {
        width: 60px;
        float: right;
      }
    }
  }
}
