@import "~styles/variables";

:export {
  sidebarExpandedWidth: $main-sidebar-expanded-width;
  sidebarCollapsedWidth: $main-sidebar-collapsed-width;
}
.story-browser{
  width: 100%;
  height: 100%;
  .content-container {
    .filters-bar-container {
      text-align: center;
      height: 100%;
    }

    .filters-bar {
      float: right;
      display: inline-block;
      .divider {
        float: right;
        width: 1px;
        height: ($element-baseline-grid * 4);
        margin: ($element-baseline-grid * 2);
        //margin-right: ($element-baseline-grid * 2);
        background-color: $color-light-extreme;
      }
      .new-asset-button {
        float: right;
        margin-top: ($element-baseline-grid * 2);
        span {
          font-weight: 300 !important;
        }
      }
    }
    .selected-bar {
      margin-left: ($element-baseline-grid * 1.5);
      .delete-asset-button-container {
        float: right;
        margin-top: 14px;
      }
    }
    .title-container{
      font-size: 12px;
      color: #808080;
      padding-top: 6px;
    }
  }
}