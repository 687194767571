@import "~styles/variables";

.story-previewer {
  width: 100%;
  height: 100%;
  position: relative;
  .label-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
}