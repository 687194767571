@import '../styles/mixins';
@import '../styles/variables';

// NOTE leverages asset-modal declared in AssetContainer.scss 
.sequence-content{
  flex-flow: row nowrap;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  //background: yellow;
  
  .asset-content.col{
    display:flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: flex-start;
    align-self: auto;
    min-height: 100%;
    height:100%;
    position: relative;
    //border:1px dashed tomato;
    video{
      flex:5 0 100%;
      //min-height:94%; // keep the elemnent from resizing on switch of source
      //max-height:94%; // allow for presence of sprites
      height: 94%;
      display: block;
      border:1px dashed transparent;
      position: relative;
      width: 100%;
      object-fit: contain;

      @include breakpoint(xxl) {        
        // min-height:93%;
        //max-height:93%;
        height: 93%;
      }
      background: #000;
    }
    
    .sprite-container{
      flex:1 1 100%; //3rd prop rep width - for flex flow row
      display: flex;
      align-items: center;
      justify-content: center;
      border:1px dashed transparent;
      //min-height:6%;
      //max-height:6%;
      height:6%;
      button{
        padding: .25rem;
        line-height: 1.5rem;
        margin: 0;
      //  z-index: 99;
      }
      @include breakpoint(xxl) {        
        //padding-top: .5rem;
        height: 7%;
        //background: tomato;
      }
    }
  }
}

