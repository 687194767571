@import "~styles/variables";
@import "~styles/mixins";

:export {
  darkBlue: $dark-blue;
  sidebarExpandedWidth: $main-sidebar-expanded-width;
  sidebarCollapsedWidth: $main-sidebar-collapsed-width;
  mediumSidebarPlusSidebarExpandedWidth: $main-sidebar-expanded-width + $main-sidebar-expanded-width;
  mediumSidebarPlusSidebarCollapsedWidth: $main-sidebar-collapsed-width + $main-sidebar-expanded-width;
  auxSidebarPlusSidebarExpandedWidth: $main-sidebar-expanded-width + $sidebar-def-width;
  auxSidebarPlusSidebarCollapsedWidth: $main-sidebar-collapsed-width + $sidebar-def-width;
  headerHeight: $header-height;
  footerHeight: $footer-height;
  backgroundDefault: $background-dark-2;
  headerExtendedHeight: $header-extended-height;
}

.content-container {
  position: absolute;
  right: 0;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  background-color: $background-dark-2;

  .relative-content-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .header-container {
    &.dark-theme {
      background-color: $background-dark-1;
    }
    &.light-theme {
      background-color: $background-light-3;
    }
    position: absolute;
    width: 100%;
    top: 0;
    margin: 0 !important;
    &.simple-mode {
      height: $header-height;
    }
    &.extended-mode {
      height: $header-extended-height;
    }
    &.extended-extra-mode {
      height: 150px;
    }
    box-shadow: rgba(21, 21, 21, 0.8) 0 2px 6px;
    z-index: 999;
    /*
    &.large-sidebar-mode {
      width: calc(100% - #{$main-sidebar-expanded-width});
    }
    &.small-sidebar-mode {
      width: calc(100% - #{$main-sidebar-collapsed-width});
    }
    */

    .header-title {
      float: left;
      width: 250px;
      box-sizing: border-box;
      padding: $element-baseline-grid ($element-baseline-grid * 3);
      p {
        -webkit-margin-before: 0;
        -webkit-margin-after: 0;
        -webkit-margin-start: 0;
        -webkit-margin-end: 0
      }
    }
    .header-content {
      width: calc(100% - 315px); // - the header-title width above
      height: 100%;
      float: right;
      position: relative;
      box-sizing: border-box;
      padding: 0 ($element-baseline-grid * 2) 0 0;
      &.align-right {
        text-align: right;
      }
      &.align-left {
        text-align: left;
      }
      .card-browser-search {
        float: left;
      }
    }
    &.select-mode {
      background-color: $color-accent-3;
      .header-content {
        width: 100%;
      }
    }
    &.dark-theme {
      .header-title {
        color: white;
      }
    }
    &.light-theme {
      .header-title {
        color: $color-dark-primary
      }
    }
    .actions-container {
      position: absolute;
      top: 47px;
      left: 70px;
    }

    .tabs-container {
      position: absolute;
      bottom: 0;
      width: 207px;
      button {
        z-index: 0 !important;
      }
    }
  }

  .container-body {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.footer-container {
  background-color: $background-dark-1;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  margin: 0 !important;
  height: $footer-height;
  padding-top: 12px;

  &.select-mode {
    background-color: $color-accent-3;
  }
}