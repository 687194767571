@import "~styles/variables";

:export {
  accentColor: $color-accent-2;
}

.question-editor {
  padding: $element-baseline-grid * 3;
  h1 {

  }
  .plus-symbol {
    width: 80px !important;
    height: 80px !important;
    position: absolute;
    fill-opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .form-container {
    background-color: white;
    position: relative;
    padding: $element-baseline-grid * 3;
    width: 80%;
    margin-left: 10%;
    margin-top: 5%;
    border-radius: $element-baseline-grid * 3;

    h1 {
      margin-top: $element-baseline-grid * 3;
      color: $color-accent-2
    }

    .main-form {
      float: left;
      width: 60%;
    }

    .main-image-container {
      float: right;
      width: 35%;
      .main-image {
        height: 260px;
        background-size: cover;
        background-position: center center;
        position: relative;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
          .plus-symbol {
            fill-opacity: 0.95;
          }
        }
      }
      margin-right: 2%;
    }

    .question-answer {
      width: 48%;
      float: left;
      border: 1px solid #bec6ca;
      padding: $element-baseline-grid * 2;
      margin-right: 2%;
      margin-bottom: 2%;
      border-radius: $element-baseline-grid;
      position: relative;
      .image-container {
        width: 150px;
        height: 150px;
        background-size: cover;
        background-position: center center;
        float: left;
        border-radius: $element-baseline-grid * 10;
        position: relative;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
          .plus-symbol {
            fill-opacity: 0.95;
          }
        }
      }
      .answers-container {
        width: calc(100% - 166px);
        float: right;
        margin-left: 16px;
      }

      .score-container {
        float: left;
        padding-left: $element-baseline-grid;
        width: 97%;
        .toggle-container {
          width: 56%;
          float: right;
          margin-top: 12px;
        }
      }
    }
  }
}
