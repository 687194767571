.asset-list-editor{
  position: relative;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  img{
    width: 100%;
    max-height: 295px;
    object-fit: contain;
  }
  .actions-container{
    position: absolute;
    text-align: right;
    right: 0;
    margin-right: 6px;
  }
  .asset-container{
    position: relative;
  }
  .gradient-top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    opacity:0;
    background: rgba(0, 0, 0, 0.38);

    /* For Mozilla/Gecko (Firefox etc) */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0));
    /* For WebKit (Safari, Google Chrome etc) */
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.38)), to(rgba(0, 0, 0, 0)));
    /* For Internet Explorer 5.5 - 7 */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(0, 0, 0, 0.38), endColorstr=rgba(0, 0, 0, 0));
    /* For Internet Explorer 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(0, 0, 0, 0.38)', endColorstr='rgba(0, 0, 0, 0)')";

  }
  &:hover{
    .gradient-top{
      opacity:1;
    }
  }
}