@import "~styles/variables";

.scene-list-browser {
  .filters-bar-container{
    height: 100%;
    padding-top: 14px;
    text-align: right;
  }
  .container-body{
    overflow: auto;
  }
}