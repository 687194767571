@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}

@mixin list-left-item() {
  padding-left: 0;
}

@mixin list-right-item() {
  padding-right: 0;
}

@mixin cms-text-styles($family: $roboto-regular, $size: 14px, $weight: 'normal') {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  @content;
}

@mixin cms-title-text {
  @include cms-text-styles($roboto-medium, 20px, normal) {
    line-height: 30px;
    @content;
  }

}

@mixin cms-sub-header2-text {
  @include cms-text-styles($roboto-regular, 18px, normal) {
    line-height: 24px;
    @content;
  }
}

@mixin cms-sub-header1-text {
  @include cms-text-styles($roboto-medium, 13px, normal) {
    line-height: 20px;
    @content;
  }
}

@mixin cms-body4-text {
  @include cms-text-styles($roboto-medium, 14px, normal) {
    line-height: 20px;
    @content;
  }
}

@mixin cms-body3-text {
  @include cms-text-styles($roboto-regular, 14px, normal) {
    line-height: 20px;
    @content;
  }
}

@mixin cms-body2-text {
  @include cms-text-styles($roboto-medium, 13px, normal) {
    line-height: 20px;
    @content;
  }
}

@mixin cms-body1-text {
  @include cms-text-styles($roboto-regular, 13px, normal) {
    line-height: 20px;
    @content;
  }
}

@mixin cms-caption-text {
  @include cms-text-styles($roboto-regular, 12px, normal) {
    line-height: 18px;
    @content;
  }
}

@mixin cms-button-text {
  @include cms-text-styles($roboto-medium, 12px, normal) {
    line-height: 18px;
    text-transform: uppercase;
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}


// Gradients
@mixin gradient-vignette($height, $from, $to) {
  height:$height;
  background:$from;
  /* For WebKit (Safari, Google Chrome etc) */
  background:-webkit-gradient(linear, left top, left bottom, from($from), to($to));
  /* For Mozilla/Gecko (Firefox etc) */
  background:-moz-linear-gradient(top, $from, $to);
  /* For Internet Explorer 5.5 - 7 */
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=$from, endColorstr=$to);
  /* For Internet Explorer 8 */
  -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr="$from", endColorstr="$to")";

}