
.basemap-editor {
  width: 100%;
  height: 100%;
  position: relative;

  .container-body {

    .geography-form-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  .sidebar-container{
    background: white;
  }
}
