
@import "variables";

/*
 * breakpoint - To include responsive media queries in our SASS
 */

@mixin breakpoint($breakpoint) {

  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    @if($breakpoint == 'xs'){
      @media (max-width: 479px) {
        @content;
      }
    }
    @else {
      @media (min-width: $value) {
        @content;
      }
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}