
.cms-card {
  width: 100%;
  position: relative;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  .card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index:999;
    .button-container {
      position: absolute;
      width: 100%;
      height: 50%;
      z-index: 10;
      .icon {
        opacity: 0.5;
        position: absolute !important;

        &.select {
          top: 0;
          left: 0;
        }
        &.edit {
          top: 0;
          right: 0;
        }
        &:hover {
          opacity: 1;
        }
      }
    }

    .gradient-top {
      position: absolute;
      top: 0;
      width: 100%;

      background: rgba(0, 0, 0, 0.38);

      /* For Mozilla/Gecko (Firefox etc) */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0));
      /* For WebKit (Safari, Google Chrome etc) */
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.38)), to(rgba(0, 0, 0, 0)));
      /* For Internet Explorer 5.5 - 7 */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(0, 0, 0, 0.38), endColorstr=rgba(0, 0, 0, 0));
      /* For Internet Explorer 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(0, 0, 0, 0.38)', endColorstr='rgba(0, 0, 0, 0)')";
    }
    .gradient-separator {
      height: 10px;
      font-size: 0;
      line-height: 0;
    }
    .gradient-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;

      background: rgba(0, 0, 0,  0.38);
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38));
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.38)));
      /* For Internet Explorer 5.5 - 7 */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(0, 0, 0, 0), endColorstr=rgba(0, 0, 0, 0.38));
      /* For Internet Explorer 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(0, 0, 0, 0)', endColorstr='rgba(0, 0, 0, 0.38)')";
    }
  }
  &.select-mode {
    .card-overlay {
      opacity: 1;
      background: transparent;
    }
  }
  .card-overlay {
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  /*&:hover {
    //box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    .image-container {
      opacity: 0.7;
    }
  }*/

  .image-container {
    position: relative;
    z-index: 99;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 99;
      position: absolute;
    }
    .type-container {
      position: absolute;
      text-transform: uppercase;
      //color: white;
      bottom: 76px;
      left: 11px;
    }
    .loading-container{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      .circular-progress{
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
    }
  }
  
  .title-container {
    padding: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 99;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // font-family: "Roboto Regular";
    // font-size: 12px;
    // font-weight: normal;
    // line-height: 18px;
    // padding-top: 6px;
    box-sizing: border-box;
    span{
      p{
        padding: 0;
        margin: 0;
      }
    }
    &.top {
      top: 0;
      bottom: auto;
      position: relative;
    }
    &.inside {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: .5rem;
    }
    &.below {
      position: relative;
    }
  }

  .visible-overlay {
    position: absolute;
    width: 100%;
    top: 0;
    z-index:999;
    pointer-events: none;
    .bottom-icons {
      position: absolute;
      bottom: 0;
      width: 100%;
      .icon-container{
        padding: 4%;
        position: relative;
        z-index: 1;
        &.bottom-icons-left {
          float: left;
        }
        &.bottom-icons-right {
          float: right;
        }
      }
    }
    .gradient-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;

      background: rgba(0, 0, 0, 0);
      /* For Mozilla/Gecko (Firefox etc) */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38));
      /* For WebKit (Safari, Google Chrome etc) */
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.38)));
      /* For Internet Explorer 5.5 - 7 */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(0, 0, 0, 0), endColorstr=rgba(0, 0, 0, 0.38));
      /* For Internet Explorer 8 */
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(0, 0, 0, 0)', endColorstr='rgba(0, 0, 0, 0.38)')";
    }
  }

  &.selected {
    .card-overlay {
      opacity: 1;
      .icon.select {
        opacity: 1;
      }
      .icon.edit {
        opacity: 1;
      }
    }
  }
}