
.text-content{
  flex: 0 1 0px;
  //border: 1px dashed red;
  display:flex;
  flex-flow: column;
  //max-width: calc(70 * (1rem/1.618));//should allow for best ledgibility -- can be overridden for specific layouts
  //height: auto;
  
  &.edit-mode {
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #029bE5;
      background: rgba(255, 255, 255, 0.14);
      cursor: pointer;
    }

    &:active {
      background: rgba(255, 255, 255, 0.05);
    }
    .inner-wrapper{
      //flex:1;
      //display: flex;
    }
    &.edit-empty{
      padding: 0;
      .empty-selector {
        flex:1 1 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        //width: 100%;
        //height: 100%;
        min-height: 250px;
        background: rgba(0, 0, 0, 0.05);
        position: relative;
        .click-to-edit-container {
          flex:1;
          display:flex;
          align-items: center;
          justify-content: center;
          align-content: center;
          color: white;
          text-align: center;
          padding: 2rem;
          //position: absolute;
          //top: 50%;
          //left: 50%;
          //transform: translate(-50%, -50%);
          h3{
            flex:1;
          }
        }
      }          
    
      &.layout-1-TL, &.layout-1-TR, &.layout-1-BL, &.layout-1-BR, 
      &.layout-1-CC, &.layout-1-CL, &.layout-1-CR, &.layout-1-TC, &.layout-1-BC{
        min-width:50%;
      }
      &.layout-5{
        min-width:60%;
      }
      &.layout-8{
        min-width:80%;
      }    
    }

  
  }
  &.edit-mode.active-editing {
    border: 2px solid #029bE5;
    background: rgba(255, 255, 255, 0.14);
    cursor: default;
  }  
}



