@import "config";
$NODE_ENV: '' !default;

$roboto-regular: 'Roboto Regular';
$roboto-medium: 'Roboto Medium';

$amc-header-height: 64px;

$body-font: $roboto-regular, sans-serif;
$heading-font: $roboto-regular, sans-serif;
$link-font: $roboto-regular, sans-serif;

$background-light-1: #fafafa;
$background-light-2: #EEEEEE;
$background-light-3: #d7d7d7;

$background-dark-1: #424242;
$background-dark-2: #2E2E2E;
$background-dark-3: #212121;

$color-accent-1: #31BCFF;
$color-accent-2: #029bE5;
$color-accent-3: #0570A4;
$color-white: #ffffff;

// Blue Colors
$light-blue-200: #87D8FF;
$light-blue-400: #7DCAEF;
$light-blue-600: #029BE5;
$light-blue-800: #0180BE;
$dark-blue: #0a5980;

$color-dark-primary: rgba(0, 0, 0, 0.87);
$color-dark-seventy: rgba(0, 0, 0, 0.70);
$color-dark-secondary: rgba(0, 0, 0, 0.54);
$color-dark-disabled: rgba(0, 0, 0, 0.38);
$color-dark-extreme: rgba(0, 0, 0, 0.10);
$color-dark-extreme2: rgba(0, 0, 0, 0.13);

$color-light-primary: rgba(255, 255, 255, 1.0);
$color-light-secondary: rgba(255, 255, 255, 0.7);
$color-light-disabled: rgba(255, 255, 255, 0.30);
$color-light-extreme: rgba(255, 255, 255, 0.10);

/*** Grid units *****/
$element-baseline-grid: 8px;
$type-baseline-grid: 4px;

/*** COMPONENTS ***/
$sidebar-def-width: 360px;
$main-sidebar-expanded-width: 200px;
$main-sidebar-collapsed-width: 80px;

$sidebar-header-height-small: 128px;
$sidebar-header-height-medium: 152px;
$sidebar-header-height-large: 176px;

$sidebar-subheader-height: 25px; /* this may end up being in the header area*/
$sidebar-actionbar-height: 72px;
$footer-height: 64px;
$header-height: 64px;
$header-extended-height: 90px;

/*** CARDS ***/
$card-image-height: 180px;
$card-caption-height: 28px;

/*** GRADIENTS ***/
$vignette-height: ($card-image-height/2) - 5;
$vignette-from-color: $color-dark-disabled;
$vignette-to-color: rgba(0, 0, 0, 0);

//Custom Neon Sky Card Grid

$break-xs: 320px;
$break-sm: 720px;
$break-md: 920px;
$break-lg: 1200px;
$break-xl: 1750px;

.neon-card-grid {
  float: left;
  padding: 1.3%;
  width: 100%;
  @media screen and (min-width: $break-xs) {
    //width: 50%;
  }
  @media screen and (min-width: $break-sm) {
    width: 50%;
  }
  @media screen and (min-width: $break-md) {
    width: 33.33333%;
    padding: 2.6%;
  }
  @media screen and (min-width: $break-lg) {
    width: 25%;
    padding: 2.4%;
  }
  @media screen and (min-width: $break-xl) {
    width: 14.285714%;
    padding: 1.3%;
  }
}

.neon-resizable-card {
  float: left;
  //padding: 3vh;
  //width: 35vh;
}

// Card Browser Grid resizing slider variables
$card-browser-breakpoint-caption: 135; // Minimum width of card to display caption - otherwise hidden
$card-browser-minimum-card-margin: 10; // Minimum margin between cards
$card-browser-minimum-card-size: 80; // Minimum value of the slider
$card-browser-maximum-card-size: 340; // Maximum value of the slider
$card-browser-default-card-size: 200; // Default value in slider