@import "~styles/variables";

.account-manager {
  position: relative;
  width: 100%;
  height: 100%;
  background: $background-light-1;
  color: $color-dark-primary;
  .editor-content-container{
    position: relative;
  }

  // .editor-previewer-container{
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }

  // .story-type-container {
  //   float: left;
  //   margin-top: 26px;
  //   margin-left: 25px;
  //   font-weight: 600;
  // }
  // .change-story-container {
  //   float: right;
  //   margin-right: 30px;
  //   margin-top: 26px;
  //   font-size: 12px;
  //   font-weight: 600;
  //   color: $color-accent-2;
  //   &:hover {
  //     cursor: pointer;
  //     color: darken($color-accent-2, 10%);
  //   }
  // }
}