@import "~styles/variables";
@import "~styles/mixins";

:export {
  contentContainerBack: $background-light-2;
  captionHeight: $card-caption-height;
  minWidthCaption: $card-browser-breakpoint-caption;
  minCardMargin: $card-browser-minimum-card-margin;
}

.graded-quiz-result-editor {
  position: absolute;
  width: 100%;
  height: 100%;
  .content-container {
    .header-container {
      box-shadow: rgba(150, 150, 150, .8) 0 2px 6px !important;
      .header-title {
        width: calc(100% - 650px);
      }
      .header-content {
        width: 550px;
      }
      .language-select {
        margin-top: 10px;
        margin-right: 15px;
      }
    }
  }

  // .slideshow-sidebar {
  //   width: $main-sidebar-expanded-width
  // }

  .graded-result-editor-container {
    // width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    .result-form-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;

      .tab-column {
        position: relative;
        float: left;
        .list-group-header {
          h2 {
            font-size: 12px;
            color: black;
            font-weight: bold;
            font-family: Roboto, sans-serif;
          }
        }
      }
      .column-right-border {
        border-right: 1px solid #ccc;
      }
      .column-general {
        width: 29%;
        height: 100%;
      }
      .column-feedback {
        width: 69%;
        height: 100%;
      }
      .multiform-container {
        padding: 13px;
        tr{
          border: 0 !important;
        }
        .label-container {
          font-family: Roboto, sans-serif;
          font-weight: 600;
          font-size: 12px;
        }
        td.mf-column.grade {
          width: 15%;
          padding-left: 0 !important;
          padding-right: 0 !important;
          .cms-select {
            padding: 0;
          }
        }
        td.mf-column.text {
          width: 45%;
          padding: 0 12px !important;
          .cms-input-text {
            padding: 0;
            height: 66px !important;
            label {
              top: 20px !important;
              left: 8px;
            }
          }
        }
        td.mf-column.asset {
          padding: 0 !important;
        }
      }

      .cms-select {
        margin-top: 14px;
        margin-bottom: 0;
      }
    }
  }
}
