@import "~styles/variables";
@import "~styles/mixins";

:export {
  contentContainerBack: $background-light-2;
  captionHeight: $card-caption-height;
  minWidthCaption: $card-browser-breakpoint-caption;
  minCardMargin: $card-browser-minimum-card-margin;
}

.slideshow-editor {
  position: absolute;
  width: 100%;
  height: 100%;
  .content-container {
    .header-container {
      box-shadow: none;
    }
  }

  // .slideshow-sidebar {
  //   width: $main-sidebar-expanded-width
  // }
  .container-body {
    display: flex;
  }
  .slideshow-editor-container {
    // width: 100%;
    //width: 100%;
    //height: 100%;
    flex: 1 0;
    display: flex;
    position: relative;
    justify-content: center;
    //float: left;
    // .slideshow-image{
    //   width: 90%;
    //   height: 90%;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   border: 1px solid white;
    // }
    .scene {
      // this is right now NOT configurable
      padding: 5vw; // using padding allows for the backgroundto still span. NOTE: if the scroll of content needs to go below, may ned to make adjustments
      flex: 1;
      display: flex;
      position: relative;

      //.content-container{
      //position: relative;
      //background: transparent;
      //}
    }
  }
  .scene-container {
    position: relative;
    //border: 1px solid green;
    display: flex;
    justify-content: center;
    flex: 1 0;
    overflow: visible;
  }
  .slide-editor-sidebar {
    float: right;
    width: $sidebar-def-width;
  }

  .slideshow-editor-topbar {
    display: flex;
    align-items: center;
    .popup-menu {
      margin-right: 20px;
      button {
        div {
          span {
            color: black !important;
          }
        }
      }
    }
    .header-select {
      width: fit-content !important;
      font-size: 13px !important;
      border-radius: 2px;
      background-color: rgba(255, 255, 255, 0.1) !important;
      margin-top: 2px;
      margin-right: 25px;
      div:nth-child(2) {
        div:nth-child(1) {
          div:nth-child(2) {
            height: 48px !important;
            padding-left: 16px !important;
            padding-right: 48px !important;
            color: white !important;
          }
        }
      }
      hr {
        display: none !important;
      }
    }
    .scene-navigation-component {
      float: right;
      color: $color-dark-primary;
      .slide-numbers-container {
        float: left;
        padding-top: 26px;
      }
      .empty-arrow {
        width: 60px;
        float: right;
      }
    }
    .change-layout-container {
      float: right;
    }
  }
}
