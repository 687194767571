@import "~styles/variables";
@import "~styles/mixins";

:export {
  backCancelButton: $color-dark-extreme2;
  backSaveButton: $color-accent-2;
  backHoverSaveButton: $color-accent-3
}

/* fixed column that can have different widths based on user settings or section */
.sidebar {
  //position: absolute;
  //top:0;
  //left:0;
  padding: 0;
  margin: 0;
  width: $sidebar-def-width;
  height: 100%;
  float: left;
  position: relative;
  z-index: 999;
  //display: table;/*not extactly sure about use of this...*/
  background: $background-light-1;
  color: $color-dark-primary;
  box-shadow: $color-dark-secondary 2px 0 9px;
  .sidebar-container {
    padding: 0;
    position: relative;
    height: calc(100% - 128px);
    width: 100%;
    overflow: auto;
  }
  .scroll-y {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh; /* @TODO have it calculate minus header + action bar */
  }

  .auxButton {
    position: absolute;
    right: ($element-baseline-grid * 7);
    top: ($element-baseline-grid * 1.75);
  }
}

.sidebar-header {
  background: $background-light-3;
  color: $background-dark-1;
  padding: 0 ($element-baseline-grid * 3);
  //height: $sidebar-header-height-small; /*($element-baseline-grid * 8)*/
  margin: 0 !important;
  position: relative;
  .sidebar-button-area {
    width: 200px;
    float: left;
    margin-top: ($element-baseline-grid * 1.75);
    margin-bottom: ($element-baseline-grid * 1.6);
  }
  h1 {
    padding: 0;
    margin: 5px 0 0 0;
  }
  h2 {
    margin: 0;
    padding: 0;
    p{margin: 0;
      padding: 0;}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 87%;
    &.onlyTitle {
      position: absolute;
      bottom: ($element-baseline-grid * 2.5);
    }
  }

  &.header-height{
    height: $header-height;
  }

  &.small-height {
    height: $sidebar-header-height-small;
  }
  &.medium-height {
    height: $sidebar-header-height-medium;
  }
  &.large-height {
    height: $sidebar-header-height-large;
    h2 {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.sidebar-action-bar {
  display: block;
  height: $sidebar-actionbar-height;
  background: $background-light-2;
  color: $background-dark-1;
  /*padding: ($element-baseline-grid * 3) ($element-baseline-grid * 3);*/
  /* height: ($element-baseline-grid * 8);*/

  /*margin-bottom: ($element-baseline-grid);*/
  margin-right: 0 !important;
}

.sidebar-subheader {
  /*this will be an h2*/
  h2 {
    margin: 0;
    padding: 0;
  }
}

.sidebar-nav {
  //background: $background-light-3;
  color: $color-dark-secondary;
  padding: 0;
  height: ($element-baseline-grid * 7);
  margin: 0 !important;
  width: 100px;
  float: left;
  .back-arrow {
    //background: $background-dark-1 !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: ($element-baseline-grid * 7) !important;

    div, svg, path {
      fill: $background-dark-1;
      color: $background-dark-1;
    }
  }

  i {
    margin-top: 15px;
    float: left;
    margin-right: 10px;
  }

}