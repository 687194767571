.card-browser-slider {
  width: 180px;
  margin: 0 12px;
  float: right;
  height: 50px;
  position: relative;
  margin-top: 0;
  .slider {
    width: 100px;
    margin: 0;
    position: absolute;
    left: 34px;
  }
  .icon-container-left, .icon-container-right {
    width: 33px;
  }
  .icon-container-left {
    left: 0;
    position: absolute;
    margin-top: 24px;
  }
  .icon-container-right {
    right: 0;
    position: absolute;
    margin-top: 17px;
  }
}