@import "~styles/variables";

:export {
  backgroundEditor: $background-light-2;
}

.quiz-editor {

  width: 100%;
  height: 100%;
  background: $background-light-1;
  color: $color-dark-primary;
}