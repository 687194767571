@import "~styles/style";


html, body {
    border:0;
    margin:0;
    padding:0;
     -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
	}

body {
    position: relative;
}

:export { accent1: $color-accent-1; accent2: $color-accent-2; accent3: $color-accent-3;}

.inner-container {
  /*display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;*/
  color: $background-light-3;
  background: $background-dark-3;
  height: 100vh; /* need to account for how ever tall the amc header is*/
  width: 100vw;
  /*border: 1px solid red;*/

}

.progress-container{
  width: 100vw;
  height: 100vh;
  .progress-indicator{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .cms-screen{
     height:100%; 
  }
}




