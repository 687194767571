.cms-accordion {
  padding: 0;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  .accordion-container{
    display: none;
    margin-bottom: 12px;
    margin-left: 16px;
    .navigate-item, .label-container {
      padding-left: 16px !important;
    }
    &.active{
      display: block;
    }
  }
  .accordion-link{
    padding: 0 !important
  }
}