@import "~styles/variables";

.layout-selector {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
  display: none;
  &.open{
    display: block;
  }
  .content-container{
    position: relative;
    width: 100%;
    height: 100%;
    .selected-bar {
      margin-left: ($element-baseline-grid * 1.5);
      .select-button-container{
        float: right;
        padding-top: 13px;
      }
    }
  }
}