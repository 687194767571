.cms-list {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 !important;
  margin: 0;

  .list-group-header {
    height: 32px;
    padding: 0 16px;
    clear: both;
    h2 {
      //padding-left: 10px;
    }

    color: #029bE5;
    line-height: 20px;
    font-family: 'Roboto Medium', sans-serif;
    font-size: 13px;
    font-weight: normal;;
  }

  .cms-list-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    margin: 0 -50px;
  }

  label {
    pointer-events: auto !important;
  }

  .cms-input-text {
    padding: 0 24px;
    &.radio{
      padding: 20px 24px;
    }
    box-sizing: border-box;
    div {
      hr {
        width: calc(100% - 48px) !important;
      }
    }

    &.radio {
      .label-container{
        user-select: none;
        color: rgba(0, 0, 0, 0.3);
        font-size: 11px;
        font-family: Roboto, sans-serif;
        padding-bottom: 9px;
      }
    }
  }

}

.cms-list-item {
  font-family: 'Roboto Regular', sans-serif;
  font-size: 14px;
  height: 48px;
  line-height: 24px;
  padding: 0 !important;
  float: left;
  width: 100%;
  .list-item-text {
    /*@include cms-body3-text;*/
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
  }
  .list-item-sublabel {
    color: rgba(0, 0, 0, 0.5);
    margin-top: -5px;
  }
  &.navigate-link {
    .cms-icon {
      top: -6px !important;
    }
  }

  .left-item {
    padding-left: 24px !important;
    padding-right: 0;
    padding-top: 12px;
  }

  .right-item {
    text-align: right;
    padding-left: 0;
    padding-right: 24px !important;
    padding-top: 12px;
  }

  a, .link {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
    padding: 0;
  }

  .link:hover, a:hover, a.active, .link.active {
    color: #029bE5;
    //background: $background-light-1;

  }

  input[type="text"] {
    border: 0;
    outline: 0;
    background: transparent;
    box-shadow: none;
    text-align: right;
    margin-right: inherit;
    border-radius: 0;
  }

  &.link {
    cursor: pointer;
  }

  // Correcting Material UI padding
  .navigate-item {
    div {
      div {
        padding: 0 24px !important;
        div {
          padding: 0 !important;
          font-size: 14px;
          margin-left: 16px;
        }

      }
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.boxed {
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    height: 72px;
    .icon-container {
      //background: #029bE5;
      padding: 9px;
      width: 24px;
      height: 24px;
      float: left;
      margin-right: 10px;
      border-radius: 21px;
      box-sizing: content-box;
      svg {
        color: #fff !important;
        fill: #fff !important;
        height: 24px !important;
        width: 24px !important;
      }
    }
    .left-item {
      padding-top: 15px;
    }
    .right-item {
      padding-top: 24px;
    }
    &:hover {
      text-decoration: none;
      background-color: #f0f0f0;
    }
    &:active {
      background-color: #ebebeb;
    }
  }

}

a.cms-list-item-container, .link {
  .cms-list-item {
    //padding: 0 ($element-baseline-grid * 3) !important;
  }
  &:hover {
    .cms-list-item.link {
      color: #029bE5;
      //background-color: $background-light-3;
    }
    svg {
      fill: #029bE5
    }
  }
  &.active {
    .cms-list-item.link {
      color: #029bE5;
      //background-color: $background-light-2;
    }
  }
}

.col-sm-12 {
  width: 100%;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-sm-6 {
  width: 50%;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-sm-5 {
  width: 41.66667%;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-sm-7 {
  width: 58.33333%;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}