@import "~styles/variables";

.project-editor{
  position: relative;
  width: 100%;
  height: 100%;
  background: $background-light-1;
  color: $color-dark-primary;
  .filters-bar-container {
    text-align: center;
    height: 100%;
  }

  .filters-bar {
    float: right;
    display: inline-block;
    .divider {
      float: right;
      width: 1px;
      height: ($element-baseline-grid * 4);
      margin: ($element-baseline-grid * 2);
      //margin-right: ($element-baseline-grid * 2);
      background-color: $color-light-extreme;
    }
    .new-asset-button {
      float: right;
      margin-top: ($element-baseline-grid * 2);
      span {
        font-weight: 300 !important;
      }
    }
  }
  .selected-bar {
    margin-left: ($element-baseline-grid * 1.5);
    .delete-asset-button-container {
      float: right;
      margin-top: 14px;
    }
  }

  .sidebar-container{
    .cms-select{
      margin-top: 20px;
    }
  }
}

.item-selector {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
  display: none;
  &.open{
    display: block;
  }
  .content-container{
    position: relative;
    width: 100%;
    height: 100%;
    .selected-bar {
      margin-left: ($element-baseline-grid * 1.5);
      .select-button-container{
        float: right;
        padding-top: 13px;
      }
    }
  }
}