@import "~styles/variables";

.theme-editor {
    width: 100%;
    height: 100%;
    background: $background-light-1;
    color: $color-dark-primary;

  .appear-enter {
    /*transition-duration: .7s;
    transition-property: opacity;
    transition-timing-function: ease-out;
    opacity: 0;*/
    opacity: 0.00;
    transform: translateX(200em);
    transition: all .5s ease-in;
  }

  .appear-enter.appear-enter-active {
    /*opacity: 1;*/
    opacity: 1;
    transform: translateX(0em);
  }

  .appear-leave {
    /*opacity: 0;*/

    transform: translateX(-200em);
    transition: all .5s ease-in;
  }

  .appear-leave.appear-leave-active {
    opacity: 0.00;
  }

}