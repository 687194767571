@import "~styles/variables";
@import "~styles/mixins";

.theme-status-indicator{
    padding: 12px ($element-baseline-grid * 3);
    
    .status-text {
      line-height: ($type-baseline-grid * 7);
      vertical-align: middle;
      margin-left: ($type-baseline-grid * 4);
    /*   @include cms-button-text;*/
    }

    .material-icons {
      vertical-align: middle;
    }

    .theme-selection-icon {
      padding-right: 10px;
      color: $color-dark-secondary;
      a {
        color: $color-dark-secondary;
        text-decoration: none;
      }
    }
}

.theme-status-indicator.active {
    color: $color-accent-2;
}
