@import "~styles/variables";
//@import "~styles/mixins";

.login {
  //background: $background-light-3;
  background-color: rgb(46, 46, 46);
  height: 100%;

  .header {
    padding: 0;
    padding-left: 0;
    width: 100%;
    height: 80px;
    position: absolute !important;
    background: rgba(255, 255, 255, 0.1);
    z-index: 2;

    .header-content {
      width: 80%;
      height: 100%;
      max-width: 1080px;
      margin: auto;
      display: flex !important;
      flex-flow: row;
      align-items: center;

      .logo-container {
        position: absolute;

        img {
          width: auto;
          height: 24px;
        }
      }
    }
  }

  .form-container {
    height: 100%;

    .outer {
      height: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      .inner {
        border-radius: 8px;
        padding: 36px;
        width: 300px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0vmax 0.8vmax 1vmax -0.5vmax rgba(0, 0, 0, 0.2),
          0vmax 1.6vmax 2.4vmax 0.2vmax rgba(0, 0, 0, 0.14),
          0vmax 0.6vmax 30vmax 0.5vmax rgba(0, 0, 0, 0.12);

        .header-container {
          width: 100%;
          text-align: center;
          color: $color-dark-seventy;

          h1 {
            font-weight: 600;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5) !important;
            margin-top: 0;
          }
        }

        .cms-form {
          .button-bank {
            justify-content: flex-end;

            button {
              width: fit-content;

              span {
                padding: 0 18px !important;
              }

              &.cancelButton {
                background: rgba(0, 0, 0, 0) !important;
                background-color: rgba(0, 0, 0, 0) !important;
                margin-right: 8px !important;

                span {
                  color: rgba(0, 0, 0, 0.54) !important;
                }
              }
              &.sendButton {
                background: #029be5 !important;
                background-color: #029be5 !important;

                span {
                  color: rgba(255, 255, 255, 1);
                }
              }
            }
          }
        }

        .password-reset-instructions {
          color: rgb(115, 115, 115);
        }
      }

      .password-reset-email-sent {
        max-width: 40ch;
        margin-bottom: 24px;
        color: rgb(168, 168, 168);
        text-align: center;
      }

      .forgot-password-link,
      .forgot-password-need-more-help-link {
        margin-top: 36px;
        a {
          color: rgba(255, 255, 255, 0.4);
          text-decoration: none;
          font-size: 14px;
        }

        a:hover {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .error {
      color: red;
      margin-top: 16px;
      font-size: 16px;
      text-align: center;
    }

    form.cms-form {
      button {
        border: 10px;
        box-sizing: border-box;
        font-family: Roboto, sans-serif;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        cursor: pointer;
        text-decoration: none;
        margin: 0px;
        margin-top: 24px !important;
        padding: 0px;
        outline: none;
        font-size: inherit;
        position: relative;
        z-index: 1;
        height: 48px !important;
        line-height: 36px;
        width: 100%;
        border-radius: 2px;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        background-color: rgb(2, 155, 229) !important;
        text-align: center;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-weight: 500 !important;
        letter-spacing: 1px !important;

        div {
          height: 48px !important;
          border-radius: 2px;
          transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
          top: 0px;
          width: auto;
          display: flex;
          justify-content: center;
          font-weight: 500 !important;
          letter-spacing: 1px !important;
          pointer-events: none;

          span {
            opacity: 1;
            margin: 0px;
            color: rgb(255, 255, 255);
            font-weight: 500 !important;
            letter-spacing: 0.08929em !important;
            -webkit-font-smoothing: antialiased !important;
            font-size: 0.875rem !important;
            text-decoration: none !important;
            text-transform: uppercase !important;
            padding: 0 16px !important;
            display: -ms-inline-flexbox !important;
            display: inline-flex !important;
            position: relative !important;
            -ms-flex-align: center !important;
            align-items: center !important;
            -ms-flex-pack: center !important;
            justify-content: center !important;

            box-sizing: border-box !important;
            min-width: 64px !important;
            height: 48px !important;
            border: none !important;
            outline: none !important;
            line-height: inherit !important;
            user-select: none !important;
            -webkit-appearance: none !important;
            overflow: hidden !important;
            vertical-align: middle !important;
            border-radius: 2px !important;
          }
        }
      }
    }

    // .cms-input-text {
    //   input {
    //     background: white !important;
    //     padding-left: 0 !important;
    //     padding-top: 16px !important;
    //   }

    //   label {
    //     top: 28px !important;
    //   }

    //   .email {
    //     margin-bottom: 8px;
    //   }
    // }
  }
}
