@import "~styles/variables";
@import "~styles/mixins";

:export {
  contentContainerBack: $background-light-2;
  captionHeight: $card-caption-height;
  minWidthCaption: $card-browser-breakpoint-caption;
  minCardMargin: $card-browser-minimum-card-margin;
}

.graded-quiz-editor {
  position: absolute;
  width: 100%;
  height: 100%;
  .content-container {
    .header-container {
      box-shadow: rgba(150, 150, 150, .8) 0 2px 6px !important;
      .header-title {
        width: calc(100% - 650px);
      }
      .header-content {
        width: 550px;
      }
    }
  }

  // .slideshow-sidebar {
  //   width: $main-sidebar-expanded-width
  // }

  .graded-quiz-editor-container {
    // width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    .tab-form-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;
      .tab-column {
        position: relative;
        float: left;
        .list-group-header {
          h2 {
            font-size: 12px;
            color: black;
            font-weight: bold;
            font-family: Roboto, sans-serif;
          }
        }
      }
      .column-right-border {
        border-right: 1px solid #ccc;
      }
      .column-question {
        width: 30%;
        height: 100%;

      }
      .column-options {
        width: 49%;
        height: 100%;
      }
      .column-settings {
        width: 20%;
        height: 100%;
      }
      .column-answer {
        width: 39.50%;
        height: 100%;
      }

      .multiform-container {
        padding: 13px;
        tr {
          border: 0 !important;
        }
        .label-container {
          font-family: Roboto, sans-serif;
          font-weight: 600;
          font-size: 12px;
        }
        td.mf-column {
          padding: 0 !important;
        }
        td.mf-column.isCorrect {
          width: 90px;
        }
        td.mf-column.title {
          width: 61%;
          .cms-input-text {
            padding: 0 15px 0 8px;
          }
        }
      }
    }
  }
  .graded-quiz-editor-sidebar {
    float: right;
    width: $sidebar-def-width;
  }

  .graded-quiz-editor-topbar {
    .scene-navigation-component {
      float: right;
      color: $color-dark-primary;
      .slide-numbers-container {
        float: left;
        padding-top: 26px;
      }
      .empty-arrow {
        width: 60px;
        float: right;
      }
    }
    .language-select {
      margin-top: 10px;
      margin-right: 15px;
    }
    .change-layout-container {
      float: right;
    }
  }
}
