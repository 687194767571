.menu-item {
  width: 100%;
  height: 48px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 16px;
  padding: 0 24px;
  padding-top: 10px;
  border-left: #212121 solid 3px;
  box-sizing: border-box;
  button{
    cursor: pointer;
    border: 0;
    background: transparent;
    color: inherit;
    &:hover, &:visited, &:active{
      color: inherit;
    }
  }
  .menu-label{
    margin-top: 5px;
    float: left;
    margin-left: 16px;
    &.small-label{
      margin-top: 1px;
    }
  }

  &:hover, &.active{
  }
}