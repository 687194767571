.cms-icon {
  position: relative;
  .tooltip {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    width: auto;
    padding: 5px 7px;
    font-size: 7pt;
    top: 4px;
    left: 46px;
    background: #424242;
    color: white;
    white-space: nowrap;
    border-radius: 2px;
    transition: opacity 0.3s cubic-bezier(.64, .09, .08, 1);
  }
  &:hover {
    .tooltip {
      opacity: 1;
    }
  }
}