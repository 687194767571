@import "~styles/variables";
@import "~styles/mixins";

.feedback-gallery-event-editor {
  position: absolute;
  width: 100%;
  height: 100%;
  .content-container {
    .header-container {
      box-shadow: rgba(150, 150, 150, .8) 0 2px 6px !important;
      .header-title {
        width: calc(100% - 650px);
      }
      .header-content {
        width: 550px;
      }
    }
  }

  // .slideshow-sidebar {
  //   width: $main-sidebar-expanded-width
  // }

  .feedback-gallery-editor-container {
    // width: 100%;
    width: 30%;
    height: 100%;
    position: relative;
    float: left;
    background: white;
  }
  .feedback-gallery-asset-container {
    width: 70%;
    height: 100%;
    position: relative;
    float: right;
    .ReactCrop{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      max-height: 100%;
      overflow: scroll;
      .ReactCrop__crop-selection{
        border-image: none;
      }
      img.ReactCrop__image {
        max-height: -webkit-fill-available;
      }
    }
  }
  .feedback-gallery-editor-topbar {
    .scene-navigation-component {
      float: right;
      color: $color-dark-primary;
      .slide-numbers-container {
        float: left;
        padding-top: 26px;
      }
      .empty-arrow {
        width: 60px;
        float: right;
      }
    }
  }
}
