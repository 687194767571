//TODO: figure out a way that setting these values in the dest woudl over ride these here
// the idea is that by setting these as !default, they will NOT override if set in the app....
@import "~styles/config"; //attempting to pull in variables in app that installed component if present -- ASSUMMES there is a alais "~styles" defined
// play button
$videoPlayIconSize: 48px !default;
$videoPlayIconSizeLg: 96px !default;

// arrow sizes

$xsArrows:8.33vw !default;
$smArrows:5.33vw !default;
$mdArrows:4.33vw !default;
$lgArrows:3.33vw !default;
//$xlArrows:2.33vw;

$scenePaddingSides: 8.33vw !default;
$scenePaddingTB: 8.33vh !default;

$isLocal : false !default;

// ------------------------------
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
        xs: 320px, // normal-res phones
        sm: 480px, // higher res phones
        md: 768px, // tablets
        lg: 950px, // laptops, desktops
        xl: 1200px,// highres desktop/wide monitors
        xxl: 1800px // kiosk
) !default;
