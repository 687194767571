
//@import '~styles/mixins';
//@import '~styles/variables';
@import '../styles/mixins';
@import '../styles/variables';

.asset-content{
  flex: 1 1 0px;
  
  // may not want this as default...maybe contain? or determined higher up the chain
  video,
  img{
    opacity: 1;
    transition: opacity .25s ease-in-out;
    max-width: 100%;
    max-height: 100%;
    //object-fit: cover;
  }
  video[data-fade],
  img[data-fade] {
	  opacity: 0;
  }
  
  &.sequence-poster-frame-container,
  &.pdf-poster-frame-container,
  &.video-poster-frame-container{
    cursor: pointer;
    flex: 1 1 100%;
    display: flex;
    position: relative;
    justify-content: center;
    //border: 1px dashed white;
    video,
    img{
        margin:0;
        //flex: 1 1 100%;
        width:auto!important;
        max-width: 100%;
        height:auto!important;
        max-height: 100% !important;
        object-fit: contain !important;
        //border: 1px dashed blue;
    }
    .play{
      position: absolute;
      width:$videoPlayIconSize;
      height:$videoPlayIconSize;
      bottom: 16px;//calc(#{$videoPlayIconSize} + )
      left:16px;

      z-index: 10;
      color: white; // from theme
      @include breakpoint(xxl) {
        width:$videoPlayIconSizeLg;
        height:$videoPlayIconSizeLg;
        left: 32px;
        bottom: 32px;//calc(#{$videoPlayIconSizeLg} +)

      }
        
        border-radius: 38px;
        padding: 3px 6px 8px 5px;
        svg{
          width:100% !important;
          height:100% !important;
          fill:inherit;
          color:inherit !important;
          // #play{
          //   width:100% !important;
          //   height:100% !important;
          // }
          #ground{
            fill:inherit;
          }
          #arrow{
            fill: currentColor !important;//not sure we will alsway want this to be white
          }          
        }
    }  
      
    .img-wrapper{
    //  border: 1px dashed tomato;
      position:relative;
      overflow: hidden;
      //flex: 1 1 0px; // we will resize with js
      display: inline-flex;
      flex-flow: column;
      align-items: flex-end;
      width: auto; // want it to always match size of the image inside
      height:auto; // want it to always match size of the image inside
      max-width: 100%;
      max-height: 100%;
      margin: 0 !important;
      padding:0 !important;
        

    }
    
    // create the gradient 
    // .img-wrapper::after{
    //     position: absolute;
    //     content:'';
    //     width: 100%;
    //     height: 30%;
    //     left: 0;
    //     bottom: 0;
    //     background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55)); // theme
    // }
        
    &.pdf-poster-frame-container{
      .img-wrapper{
          justify-content: center;
          .play-touch {
            align-self:center;
            background: rgba(59, 59, 59, 0.79);
            left: auto;
            bottom: auto;//calc(#{$videoPlayIconSizeLg} +)
            @include breakpoint(xxl) {
              left: auto;
              bottom: auto;//calc(#{$videoPlayIconSizeLg} +)

            }
          }
      }
      .img-wrapper::after{
        background: none;
      }
    }


    &.with-text{
    // account for if there is asset text so icon stays over image 
      .play{
      //  bottom:;
      }
    }
  }  
  
  &.edit-mode {
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #029bE5;
      background: rgba(255, 255, 255, 0.14);
      cursor: pointer;
    }

    &:active {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  &.edit-mode.active-editing {
    border: 2px solid #029bE5;
    background: rgba(255, 255, 255, 0.14);
    cursor: default;
  }  
  &.empty-selector,
  &.no-image {
      background: rgba(0, 0, 0, 0.05) !important;
      
        flex: 1 1 100%;
        display:flex;
        align-items: center !important;
        justify-content: center !important;
        align-content: center !important;  
        background: rgba(0, 0, 0, 0.05);
        position: relative;
        .click-to-edit-container {
          color: white;
          flex:1;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          align-content: center;
          padding: 1rem;
          //position: absolute;
          //top: 50%;
          //left: 50%;
          //transform: translate(-50%, -50%);
          h3{
            flex:1;
          }
        }
      
      // require abs position
      &.layout-3{
        .click-to-edit-container{
          position: absolute; // do this to ignore the padding used to create Square Element
          top:0;
          left:0;
          right:0;
          bottom:0;
        }    
      }
      &.layout-10{
        width:100%;
      }
      // these layouts don't have a bounding height
      &.layout-10, 
      &.layout-2-right,
      &.layout-2-left{
        min-height: 100%; //should fill space determined by layout
      }
  }
}

.asset-modal {
  // very similar to scene navigation - might be able to reuse/share
  padding-top: 0 !important;
  
  > div:first-child {
    background: inherit !important;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .modal-overlay {
    background: rgba(0,0,0, .85) !important; //theme?
  }
  .modal-content {
    z-index: 5;
    flex: 1;
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;

    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
    display: flex;
    justify-content: center;
    
    transform: translate(0px, 0px) !important;

    .modal-paper {
      background: inherit !important;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      // we keep this abs position because it shoudl have no effect on video margins
      header {
        position: absolute;
        height: 40px;
        top:0;
        left:0;
        //flex: 0 0 40px;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        padding: .25rem .25rem;
        //border: 1px dashed tomato;
        .modal-close{
          width : 30px;
          height: 30px;
          z-index: 125;
          display: flex;
          fill: white; // theme?
          align-items: center;
          cursor:pointer;
            .cms-icon{
              flex: 1;
              display: flex;
              width: 100%;
              height: 100%;
              align-items: center; 
              color: inherit !important;
              fill: inherit !important;
              svg{
                color: inherit !important;
                fill: inherit !important;
                path{
                  color: inherit !important;
                  fill: inherit !important;  
                }
              }
            }   
          }
      }      
      
    }

  }

  .modal-body {
    flex:1;
    z-index: 25;
    
    width: 85vw !important;
    min-width: 85vw !important;
    max-width: 85vw !important;

    height: 85vh !important;
    min-height: 85vh !important;
    max-height: 85vh !important;
    //border:1px dashed cyan;
    padding: 0 !important;
    height: 100%;
    display: flex;
    
    .sequence-content,
    .pdf-content,
    .video-content {
      flex: 1;
      position: relative;
      top: 0;
      left: 0;
      //border:1px dashed orange;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-content: stretch;
      align-items: center;
    
      overflow: auto;
      margin: 0;
      z-index: 5;
      
        //video-container    
        .asset-content{
          flex: 1 1 100%;
          width: 100%;
          //border:1px dashed cyan;
          align-self: center;
          overflow: hidden;
          width: 100%;
          
          video{
            object-fit: contain;
          }
        }  
  
    
    }
    
  }

}


