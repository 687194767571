@import "~styles/variables";

.dashboard {
  .container-body {
    //background-image: linear-gradient(346deg, #2b87da 0%, #29c4a9 100%) !important;
    .logo-container{
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      position: absolute;
      opacity: 0.2;
    }
  }
  height: 100%;
  .left-container {
    background: $background-light-1;
    color: $color-dark-primary;
  }
}






