@import "~styles/variables";

.asset-selector {
  width: 100%;
  height: 100%;
  background: white;
  &.is-modal {
    position: absolute;
    display: none;
    z-index: 1001;
    &.open {
      display: block;
    }
    .header-title {
      margin-left: 35px;
      width: 215px;
    }
  }
  .content-container {
    position: relative;
    width: 100%;
    .filters-bar-container {
      text-align: center;
      height: 100%;
      .close-modal-container {
        position: fixed;
        left: 20px;
        top: 22px;
      }
    }
    .filters-bar {
      float: right;
      display: inline-block;
      .divider {
        float: right;
        width: 1px;
        height: ($element-baseline-grid * 4);
        margin: ($element-baseline-grid * 2);
        //margin-right: ($element-baseline-grid * 2);
        background-color: $color-light-extreme;
      }
      .new-asset-button {
        float: right;
        margin-top: ($element-baseline-grid * 2);
        span {
          font-weight: 300 !important;
        }
      }
    }
    .selected-bar {
      margin-left: ($element-baseline-grid * 1.5);
      .select-button-container {
        float: right;
        margin-top: 14px;
      }
    }

  }

  .card-browser-slider {
    width: 180px;
    margin: 0 12px;
    float: right;
    height: 50px;
    position: relative;
    margin-top: 0;
    .slider {
      width: 100px;
      margin: 0;
      position: absolute;
      left: 34px;
    }
    .icon-container-left, .icon-container-right {
      width: 33px;
    }
    .icon-container-left {
      left: 0;
      position: absolute;
      margin-top: 24px;
    }
    .icon-container-right {
      right: 0;
      position: absolute;
      margin-top: 17px;
    }
  }

}