@import "~styles/variables";

:export {
  itemColor: $color-accent-2;
  colorSecondary: lighten($color-dark-secondary, 35%);
}

.sidebar {
  .quiz-type-container {
    float: left;
    margin-top: 26px;
    margin-left: 25px;
    font-weight: 600;
  }
  .change-quiz-container {
    float: right;
    margin-right: 30px;
    margin-top: 26px;
    font-size: 12px;
    font-weight: 600;
    color: $color-accent-2;
    &:hover {
      cursor: pointer;
      color: darken($color-accent-2, 10%);
    }
  }
}

.questions-list {

  .question-item {
    min-height: ($element-baseline-grid * 11);
    padding: ($element-baseline-grid * 3);
    border-bottom: 1px solid $color-dark-extreme;
    background: $color-light-secondary;
    color: $color-dark-secondary;
    position: relative;
    &.active {
      background: darken($color-light-secondary, 12%);
      border-bottom-color: $color-accent-2;
    }
    &:hover {
      background: darken($color-light-secondary, 5%);
      cursor: pointer;
      border-bottom-color: $color-accent-2;
    }
    &:active {
      background: darken($color-dark-extreme2, 35%);
    }
    .question-title {
      margin-left: 32px;
    }
    .question-icon {
      position: absolute;
      top: 30px;
    }

    &.add-new-question {
      text-align: center;
      padding: 22px 80px;
      color: lighten($color-dark-secondary, 35%);
      .plus-symbol {
        width: 35px !important;
        height: 35px !important;
        float: left;
      }
      .new-question-text {
        margin-top: 10px;
        display: inline;
        float: left;
        margin-left: 10px;
      }
    }
  }

}
