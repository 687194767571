//@import '~styles/mixins';
//@import '~styles/variables';
@import "../styles/mixins";
@import "../styles/variables";

// used to make sure on small the layout stacks
// -------------------------
// used by .layout-2-left, .layout-2-right, .layout-3, .layout-4, .layout-4-b, .layout-5, .layout-6-right, .layout-6-left, .layout-7, .layout-9, .layout-9-b
@mixin stackLayoutSmall() {
  align-items: flex-start; //-- this causes layout to collapse and stack
  @include breakpoint(md) {
    // reset
    align-items: normal;
  }
}

.slideshow-slide {
  // NOTE the layout class gets assigned to this div in addition to .content-container
  flex: 1; //assumes parent will be set to display flex, and most likely as row nowrap - the default
  display: flex;
  overflow: visible;
  top: 0;
  left: 0;

  //border: 1px dashed green;
  z-index: 5;
  img {
    object-fit: contain; //default and con be overwritten for on a layout basis
    width: 100% !important;
    height: 100%;
  }

  // holds the various slide content and keeps it separate from background asset
  // NOTE the layout class gets assigned to this div as well

  // section
  .content-container {
    flex: 1;
    position: relative;
    background: transparent;
    @if ($isLocal) {
      border: 1px dashed gray;
    }
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: stretch;
    overflow: visible; //allows for body scrolling on smaller devices

    // Larger than Mobile
    @include breakpoint(md) {
      flex-flow: row nowrap;
    }

    z-index: 10;

    // NOTE: may be easier to understand as these being placehold selectors a layout would use as an extend
    // default colun treatment
    // note same thing is defined in layout.scss
    .col {
      flex: 1 0 100%;
      //margin-right: 2vw;//change for now to hard code 48px
      @if ($isLocal) {
        border: 1px dashed yellow;
      }
      order: 1;
      //height: auto; // makes sure they fill the space
      display: flex;
      padding: 0 !important;
      flex-flow: row wrap;
      align-items: flex-start;
      overflow: visible; // some layouts may want overflow hidden

      // &:only-child{
      //   border: 1px dashed orange;
      //   max-width: 50%;// NOTE: might need to be a % or vw?
      // }

      @include breakpoint(md) {
        margin-right: 2vw; //change for now to hard code 48px
        flex: 1 1 50%;
        height: 100%; // makes sure they fill the space
      }

      &:last-of-type {
        @if ($isLocal) {
          border: 1px dashed orange;
        }
        margin-right: 0;
      }
    }

    .text-content {
      flex: 0 1 100%; //start point, can be overridden
      @if ($isLocal) {
        border: 1px dashed red;
      }

      max-width: calc(
        70 * (1rem / 1.618)
      ); //should allow for best ledgibility -- can be overridden for specific layouts
      height: auto;
    }

    .asset-content {
      flex: 1 1 0;
      height: auto;
      //display: flex;
      @if ($isLocal) {
        border: 1px dashed purple;
      }
      //max-height: 1px;// seems to correct the issue with flow of next element
      img {
        //width: 100% !important;
        object-fit: contain;
      }
      //&.image-container,
      &.sequence-poster-frame-container,
      &.pdf-poster-frame-container,
      &.video-poster-frame-container {
        //flex: 1;
        @if ($isLocal) {
          border: 1px dashed green;
        }
        //min-height: 50%;//make sure it takes up space, though would like for this to be determined by image
        //
        .img-wrapper {
          // display: flex;
          // height: auto;
          // width: auto;
          // align-items: center;
          @if ($isLocal) {
            border: 1px dashed pink;
          }
          video,
          img {
            //width: auto !important;
            // height: 100%;
          }
        }
      }

      &.with-text {
        display: flex;
        flex-flow: column nowrap;
        //max-height: 1px;// seems to correct the issue with flow of next element
      }
    }

    // --------------------
    // layout classes that change up the way the flexbox is strucutured
    // they get assigned to scene and the container
    // should have a class name ofr each of the layouts in the json
    // NOTE: make make more sense to convert to mixins?
    %layout1Col {
      flex-flow: column wrap;
      order: 0;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      @if ($isLocal) {
        border: 1px dashed darkcyan;
      }
    }

    %layout1ColCenter {
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    %layout1ColText {
      flex: 1 1 100%;

      @if ($isLocal) {
        border: 1px dashed darkblue;
      }
      @include breakpoint(md) {
        flex: 0 1 50%;
        max-width: 50%;
      }
    }

    %layout1Break {
      @include breakpoint(md) {
        order: 2;
        //align-content: flex-start;
      }
    }
    //---------------------------

    &.layout-1-TL {
      .col {
        @extend %layout1Col;
        justify-content: flex-start;
        align-items: flex-start;
        .text-content {
          @extend %layout1ColText;
          align-items: flex-start;
        }
        @extend %layout1Break;
      }
    }

    &.layout-1-TR {
      .col {
        @extend %layout1Col;
        justify-content: flex-start;
        align-items: flex-end;
        .text-content {
          @extend %layout1ColText;
        }
        @extend %layout1Break;
      }
    }

    &.layout-1-BL {
      // one column

      .col {
        @extend %layout1Col;
        justify-content: flex-end;
        align-items: flex-start;
        .text-content {
          @extend %layout1ColText;
          justify-content: flex-end;
          align-items: flex-start;
        }
        @extend %layout1Break;
      }
    }

    &.layout-1-BR {
      // one column

      .col {
        @extend %layout1Col;
        justify-content: flex-end;
        align-items: flex-end;
        .text-content {
          @extend %layout1ColText;
          justify-content: flex-end;
        }
        @extend %layout1Break;
      }
    }

    &.layout-1-CC {
      .col {
        @extend %layout1Col;
        @extend %layout1ColCenter;
        justify-content: center;
        align-content: center;
        align-items: center;
        .text-content {
          @extend %layout1ColText;
          justify-content: center;
          align-content: center;
          //align-items: center;
        }
        @extend %layout1Break;
      }
    }

    &.layout-1-CL {
      .col {
        @extend %layout1Col;
        @extend %layout1ColCenter;
        justify-content: center;
        align-items: flex-start;
        .text-content {
          @extend %layout1ColText;
          justify-content: center;
        }
      }
    }

    &.layout-1-CR {
      .col {
        @extend %layout1Col;
        @extend %layout1ColCenter;
        align-content: center;
        align-items: flex-end;
        .text-content {
          @extend %layout1ColText;
          justify-content: center;
          align-content: center;
        }
      }
    }

    &.layout-1-TC {
      .col {
        @extend %layout1Col;
        @extend %layout1ColCenter;
        justify-content: flex-start;
        align-items: center;
        .text-content {
          @extend %layout1ColText;
          justify-content: center;
          justify-content: flex-start;
        }
      }
    }

    &.layout-1-BC {
      .col {
        @extend %layout1Col;
        @extend %layout1ColCenter;
        align-items: center;
        justify-content: flex-end;
        .text-content {
          @extend %layout1ColText;
          justify-content: flex-end;
        }
      }
    }

    // --------------------
    // placeholders for layout 2
    // asset container and image fit 100% height of column, then the object fit prop determines what is display
    %layout2Col {
      flex-flow: column wrap;
      justify-content: center;
      align-content: center;
    }

    %layout2ColBreak {
      @include breakpoint(md) {
        //order:2;
        align-content: flex-start;
      }
    }

    %layout2ColAsset {
      flex: 0 0 100%;
      display: flex; //row nowrap
      align-items: center; // keep image center
      width: 100%;
      height: auto;
      max-height: 100%;
      padding: 0;
    }

    %layout2ColAssetPoster {
      min-height: 100%;
    }

    %layout2ColAssetTag {
      width: 100% !important;
      height: 100%;
      object-fit: contain;
    }

    %layout2ColText {
      flex: 0 0 0px;
      display: block;
      @if ($isLocal) {
        border: 1px dashed blue;
      }
    }
    // --------------------
    // image on left, text on right
    &.layout-2-left {
      .col {
        @extend %layout2Col;
        .asset-content {
          @extend %layout2ColAsset;

          &.image-container,
          &.sequence-poster-frame-container,
          &.pdf-poster-frame-container,
          &.video-poster-frame-container {
            @extend %layout2ColAssetPoster;
            .img-wrapper {
              //  height: auto;
            }
          }

          video,
          img {
            @extend %layout2ColAssetTag;
          }
        }

        .text-content {
          @extend %layout2ColText;
        }
        @extend %layout2ColBreak;
      }
      &.scroll-text {
        .col {
          &:last-of-type {
            // text is in right column
            justify-content: flex-start;
            overflow: scroll;
          }
          // dont want asset to scroll with page
          position: relative; // to help give anchor for the fixed asset
        }
      }
    }
    // image on right - text on left
    &.layout-2-right {
      .col {
        @extend %layout2Col;
        .asset-content {
          @extend %layout2ColAsset;

          &.image-container,
          &.sequence-poster-frame-container,
          &.pdf-poster-frame-container,
          &.video-poster-frame-container {
            @extend %layout2ColAssetPoster;
            .img-wrapper {
              //height: auto;
            }
          }

          video,
          img {
            @extend %layout2ColAssetTag;
          }
        }

        .text-content {
          @extend %layout2ColText;
        }
        @extend %layout2ColBreak;
      }
      &.scroll-text {
        .col {
          &:first-of-type {
            justify-content: flex-start;
            overflow: scroll;
          }
        }
      }
    }

    &.layout-3 {
      // 3 column
      .col {
        justify-content: center;
        display: flex;
        flex-flow: row wrap;

        .asset-content {
          flex: 1 0 100%;
          position: relative;
          @if ($isLocal) {
            border: 1px dashed green;
          }
          padding-top: 100%; // match column width making 1:1 ratio

          video,
          audio,
          img {
            position: absolute; // do this to ignore the padding-top
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          &.pdf-poster-frame-container,
          &.sequence-poster-frame-container,
          &.video-poster-frame-container {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            align-content: center;
            .img-wrapper {
              position: absolute; // do this to ignore the padding-top that creates the square
              //overflow: hidden;
              top: 50%;
              transform: translateY(-50%);
              //right:0;
              //bottom:0;
              //left:0;
              // max-width: 100%;
              // max-height: 100%;
              // width: auto;
              // height: auto;
              video,
              img {
                position: relative; // reset img if since its inside the wrapper
                // height: auto;
                // width: auto;
                flex: none;
                width: 100%;
                // max-width: 100%;
                // max-height: 100%;
                // left:auto;
                // right:auto;
                // top: auto;
                // bottom:auto;
              }
            }
          }
          // may be smarter to make behavior of first child?
          //:first-child,
        }

        .text-content {
          flex: 1 1 0;
          @if ($isLocal) {
            border: 1px dashed blue;
          }
        }

        @include breakpoint(md) {
          //order:2;
          align-content: flex-start;
        }
      }
    }

    &.layout-4,
    &.layout-4-b {
      .col {
        @include breakpoint(md) {
          flex: 3 3 0;
          flex-flow: column nowrap;
        }

        &:nth-child(2) {
          @if ($isLocal) {
            border: 1px dashed tan;
          }
          .asset-content {
            //flex: 1 1 0;
            height: 100%; // make sure contain images dont break column height
            //  display: flex;
            //align-items: flex-start; //move the image to top, and not centered in the space
            //align-content: flex-start;
            //max-height: 100%; //only if we want to force image to stay on screen and not scroll if needed
            padding: 0;
            video,
            img {
              height: 100%; // makes the image not grow longer than the column - and centers the landscape ones
              object-fit: contain; //portrait images my cease to fill width
              object-position: top; //be sure even contain moves to top
            }
          }
          @include breakpoint(md) {
            flex: 6 6 0;
            overflow: hidden;
          }
        }

        .text-content {
          flex: 1 1 0;
        }

        .asset-content {
          width: 100%;
          flex: 0 1 auto;
          height: auto;
          //display:flex; // makes horz image not work, but portrait work
          video,
          img {
            height: auto;
            //object-fit: none;
          }
          &.video-poster-frame-container {
            //flex-flow: row;
            flex: none;
            height: auto;
            min-height: auto;
            .img-wrapper {
              //max-width: 100%;
              //max-height: 100%;
              //height: auto;
              flex: none;
              //flex-flow: row;
              video,
              img {
                flex: none;
                position: relative;
                //  height: auto;
                object-fit: contain;
              }
            }
          }
        }
        overflow: visible;
      }
    }

    &.layout-5 {
      .col {
        flex-flow: row;
        align-items: flex-end;
        &:last-of-type {
          flex-flow: row;
          //justify-content: flex-end;
          //align-content: flex-end;
          align-items: flex-end;
        }
        .text-content {
          max-width: 100%;
          flex: 1 0 0px;
          @if ($isLocal) {
            border: 1px dashed lime;
          }
          @include breakpoint(md) {
            max-width: 60%;
          }
        }
        .asset-content {
          height: auto;
          img {
            flex: none;
            height: auto;
            object-fit: cover;
            //object-position: bottom;
          }
        }
      }
    }

    &.layout-6-left {
      .col {
        flex-flow: row;
        @include breakpoint(md) {
          //flex-flow: column wrap;
        }
        //flex: 1 1 100%;

        &:first-of-type {
          flex: 1 1 0;
          align-items: flex-start;
        }
        &:last-of-type {
          @include breakpoint(md) {
            flex: 3 3 0;
          }
        }
        .text-content {
          flex: 1 0 0px;
          max-width: 100%;
          @if ($isLocal) {
            border: 1px dashed lime;
          }
        }
        .asset-content {
          height: 100%;
          video,
          img {
            height: 100%;
            object-fit: cover;
          }
          &.video-poster-frame-container {
            align-items: flex-start;
            .img-wrapper {
              flex-flow: row;
              height: auto;
            }
          }
        }
      }
    }

    &.layout-6-right {
      .col {
        flex-flow: row;
        @include breakpoint(md) {
          //flex-flow: column wrap;
        }
        //flex: 1 1 100%;

        &:first-of-type {
          @include breakpoint(md) {
            flex: 3 3 0;
          }
        }
        &:last-of-type {
          flex: 1 1 0px;
          align-items: flex-start;
        }
        .text-content {
          //max-width: 60%;
          flex: 1 0 0px;
          max-width: 100%;
          @if ($isLocal) {
            border: 1px dashed lime;
          }
        }
        .asset-content {
          height: 100%;
          video,
          img {
            height: 100%;
            object-fit: cover;
          }
          &.video-poster-frame-container {
            align-items: flex-start;
            .img-wrapper {
              flex-flow: row;
              height: auto;
            }
          }
        }
      }
    }

    &.layout-7 {
      .col {
        flex-flow: column;
        // the first 2 columns have 2 assets with potential text below
        .asset-content {
          flex: 1 1 50%;
          width: 100%;
          height: auto;
          //max-height: 50%;
          margin-bottom: 2rem; // gets removed on last one
          &.with-text {
            display: flex;
            flex-flow: column;
            //justify-content: flex-start;
            //align-items: flex-start;
            height: auto;
            //max-height: 50%;
            video,
            img {
              //flex: 1 1 80%;
              width: 100%;
              object-fit: contain;
              object-position: center;
              height: auto;
              max-height: 80%;
              @if ($isLocal) {
                border: 1px dashed blue;
              }
            }

            &.video-poster-frame-container {
              .img-wrapper {
                flex: none;
                //width: 100%;
                overflow: hidden;
                height: auto;
                video,
                img {
                  height: auto;
                  flex: none;
                  position: relative;
                  //height: 100%;
                  //object-position: bottom; //right now it the only way to get it to work with the gradient
                }
              }
            }
            .asset-text {
              flex: 1 1 20%;
              width: 100%;
              @if ($isLocal) {
                border: 1px dashed white;
              }
              //align-self: flex-end;
            }
          }
          &:last-of-type {
            @if ($isLocal) {
              border: 1px dashed lime;
            }
            margin-bottom: 0;
          }
        }
      }
    }

    &.layout-8 {
      .col {
        align-items: center;
        justify-content: center;
      }
      .text-content {
        min-width: 80%;
        max-width: 80%;
        flex: 0 0 0px;
        @if ($isLocal) {
          border: 1px dashed lime;
        }
      }
    }

    &.layout-9,
    &.layout-9-b {
      .col {
        .asset-content {
          &.with-text {
            flex-flow: column nowrap;
            //max-height: none;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start; //move the image to top, and not centered in the space
            .asset-text {
              flex: 1 1 0;
            }
            img {
              flex: 5 5 0;
              width: 100% !important;
              height: auto;
              //object-fit: none;
              max-height: none;
            }
            &.video-poster-frame-container {
              .img-wrapper {
                flex: none;
                //width: 100%;
                height: auto;
                video,
                img {
                  height: auto;
                  flex: none;
                  position: relative;
                  //height: 100%;
                  //object-position: bottom; //right now it the only way to get it to work with the gradient
                }
              }
            }
          }
          &.video-poster-frame-container {
            .img-wrapper {
              flex: none;
              //width: 100%;
              height: auto;
              video,
              img {
                height: auto;
                flex: none;
                position: relative;
                //height: 100%;
                //object-position: bottom; //right now it the only way to get it to work with the gradient
              }
            }
          }
        }
      }
    }

    &.layout-10 {
      .col {
        align-items: center;
        justify-content: center;
        .asset-content {
          height: 100%; // keep the asset contrained to column
          align-items: center;
          justify-content: center;
          //&.image-container,
          &.video-poster-frame-container {
            //  justify-content: center;
            //  align-items: center;
            .img-wrapper {
              height: auto;
              width: auto;
              //  flex:none !important;
              video,
              img {
              }
            }
          }
          &.pdf-poster-frame-container {
            // my concern here is that this may only work for a ladscape, not portrait??
            max-height: 100%;
            max-width: 100%;
            flex: none !important; // the height 100% makes the pdf poster not centered...
            align-items: center;
            align-content: center;
            .img-wrapper {
              flex-flow: row;
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }

    &.layout-11-a {
      .col {
        @include breakpoint(md) {
          flex: 3 3 0;
          flex-flow: column nowrap;
        }

        &:nth-child(2) {
          @if ($isLocal) {
            border: 1px dashed tan;
          }
          .asset-content {
            @include breakpoint(md) {
              height: 100%; // make sure contain images dont break column height, can't be auto or the img won't be able to size correct
              max-height: 100%; //
              padding: 0;
              video,
              img {
                height: 100%; // makes the image not grow longer than the column - and centers the landscape ones
                object-fit: contain; //portrait images my cease to fill width
                object-position: top; //be sure even contain moves to top
              }
            }
          }

          // col responsive
          @include breakpoint(md) {
            flex: 6 6 0;
            overflow: hidden;
          }
        }

        .text-content {
          flex: 1 1 0;
        }

        .asset-content {
          width: 100%;

          display: inline-block;
          @include breakpoint(md) {
            flex: 0 1 auto;
            height: auto;
            // display: inline-block; // makes horz image not work, but portrait work
            //display:flex; // makes horz image not work, but portrait work
            video,
            img {
              height: auto;
              //object-fit: none;
            }
            &.video-poster-frame-container {
              //flex-flow: row;
              flex: none;
              height: auto;
              min-height: auto;
              display: inline-block;
              .img-wrapper {
                display: inline-block;
                //max-width: 100%;
                //max-height: 100%;
                //height: auto;
                flex: none;
                //flex-flow: row;
                video,
                img {
                  flex: none;
                  position: relative;
                  //  height: auto;
                  object-fit: contain;
                }
              }
            }
          }
        }
        overflow: visible;
      }
    }

    &.layout-11-b {
      .col {
        @include breakpoint(md) {
          flex: 3 3 0;
          flex-flow: column nowrap;
        }

        &:nth-child(1) {
          @if ($isLocal) {
            border: 1px dashed tan;
          }
          .asset-content {
            @include breakpoint(md) {
              height: 100%; // make sure contain images dont break column height, can't be auto or the img won't be able to size correct
              max-height: 100%; //
              padding: 0;
              video,
              img {
                height: 100%; // makes the image not grow longer than the column - and centers the landscape ones
                object-fit: contain; //portrait images my cease to fill width
                object-position: top; //be sure even contain moves to top
              }
            }
          }

          // col responsive
          @include breakpoint(md) {
            flex: 6 6 0;
            overflow: hidden;
          }
        }

        .text-content {
          flex: 1 1 0;
        }

        .asset-content {
          width: 100%;

          display: inline-block;
          @include breakpoint(md) {
            flex: 0 1 auto;
            height: auto;
            // display: inline-block; // makes horz image not work, but portrait work
            //display:flex; // makes horz image not work, but portrait work
            video,
            img {
              height: auto;
              //object-fit: none;
            }
            &.video-poster-frame-container {
              //flex-flow: row;
              flex: none;
              height: auto;
              min-height: auto;
              display: inline-block;
              .img-wrapper {
                display: inline-block;
                //max-width: 100%;
                //max-height: 100%;
                //height: auto;
                flex: none;
                //flex-flow: row;
                video,
                img {
                  flex: none;
                  position: relative;
                  //  height: auto;
                  object-fit: contain;
                }
              }
            }
          }
        }
        overflow: visible;
      }
    }
  }

  // this is a result of the fact we can have the layout assigned to the top levele div AND the content-container
  // NOTE: may make more sense to just target at this level... need to think about it
  &.layout-2-left,
  &.layout-2-right,
  &.layout-3,
  &.layout-4,
  &.layout-4-b,
  &.layout-5,
  &.layout-6-right,
  &.layout-6-left,
  &.layout-7,
  &.layout-9,
  &.layout-9-b {
    @include stackLayoutSmall();
  }
}

button:disabled {
  opacity: 0.5;
}
