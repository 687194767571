@import "~styles/variables";

:export {
  sidebarExpandedWidth: $main-sidebar-expanded-width;
  sidebarCollapsedWidth: $main-sidebar-collapsed-width;
  auxSidebarPlusSidebarExpandedWidth: $main-sidebar-expanded-width + $sidebar-def-width;
  auxSidebarPlusSidebarCollapsedWidth: $main-sidebar-collapsed-width + $sidebar-def-width;
  captionHeight: $card-caption-height;
  minWidthCaption: $card-browser-breakpoint-caption;
  minCardMargin: $card-browser-minimum-card-margin;
}

.library {

  width: 100%;
  height: 100%;
  position: relative;
  .left-container {
    background: $background-light-1;
    color: $color-dark-primary;
    height: 100vh;
    padding: 0;
    /*overflow-y: auto;
    overflow-x: hidden;*/
  }
  .asset-editor-topbar {
    padding-top: 13px;
  }

  .asset-selector{
    z-index: 0;
    position: absolute;
    .content-container{
      right: 0;
      position: absolute;
    }
    .title-container{
      font-size: 12px;
      color: #808080;
      padding-top: 6px;
    }
  }
  
  .content-container.asset-viewer{
    position: absolute;
    z-index: 99;
  }

  .sidebar{
    .sidebar-container{
      height:calc(100% - 200px);
    }
  }

}