@import "~styles/variables";
@import "~styles/mixins";

:export {
  contentContainerBack: $background-light-2;
  captionHeight: $card-caption-height;
  minWidthCaption: $card-browser-breakpoint-caption;
  minCardMargin: $card-browser-minimum-card-margin;
}

.scene-editor {
  position: relative;
  width: 100%;
  height: 100%;
  .content-container {
    .header-container {
      box-shadow: none;
    }
  }

  .scene-sidebar {
    width: $main-sidebar-expanded-width;
    float: left;
  }

  .editor-container {
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
  }
  
  .background-asset, .background-image, .background-overlay-scrim{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
  }

  .asset, .background-asset{
    img{
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
    }
    video{
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-sizing: border-box;    
    }      
  }
}