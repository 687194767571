.card-browser-search {
  background: rgba(255, 255, 255, 0.06);
  transition: background-color 0.2s ease;
  margin-left: 12px;
  margin-top: 10px;
  padding: 10px;
  display: inline-block;
  border-radius: 2px;

  &.focused {
    background: white;
    svg {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
  .search-input {
    background: transparent;
    min-width: 300px;
    border: none;
    outline: none;
    margin-top: 2px;
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87);
    float: left;
    margin-left: 24px;
  }

  input::-webkit-input-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.26);
  }
  input:focus::-webkit-input-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.30);
  }
  /* Firefox < 19 */
  input:-moz-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.26);
  }
  input:focus:-moz-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.30);
  }
  /* Firefox > 19 */
  input::-moz-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.26);
  }
  input:focus::-moz-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.30);
  }

  /* Internet Explorer 10 */
  input:-ms-input-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.26);
  }
  input:focus:-ms-input-placeholder {
    line-height: 20px;
    font-family: "Roboto Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.30);
  }
}
