@import "~styles/variables";

.analytics-module {
  h2{
    color:$color-dark-primary
  }
  .container-body {
    background: white;
    overflow: scroll;
    hr {
      border: 0;
      height: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.4);
      border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      clear: both;
      margin: 0 30px;
    }
    .filter-bar-container {
      height: $header-height;
      background: $background-light-2;
      padding: 8px 30px;
      box-sizing: border-box;
      div:first-of-type {
        float: left;
      }
      .custom-date-range-container{
        > div{
          float: left;
        }
      }
    }
    .overview-language-container {
      min-height: 250px;
      padding: 30px;
      position: relative;
      .overview-container {
        width: 50%;
        float: left;
        height: 100%;
        padding-bottom: 30px;
        div {
          background-color: $color-accent-1;
          color: white;
          border-radius: 20px;
          padding: 20px;
          float: left;
          text-align: center;
          margin-right: 25px;
          span.main-text {
            font-size: 55px;
            font-weight: normal;
          }
        }
      }
      .language-container {
        width: 50%;
        float: right;
        height: 100%;
      }
    }
    .sessions-by-container {
      min-height: 370px;
      padding: 30px;
      h2 {
        float: left
      }
      div:first-of-type {
        margin-left: 10px;
        margin-top: 1px;
      }
    }
    .popular-content-container {
      min-height: 350px;
      padding: 30px;
    }
  }

}