@import "~styles/variables";

.cms-asset-viewer {
  width: 100%;
  height: 100%;
  .asset-container {
    width: 100%;
    height: 100%;
    position: relative;
    img, video {
      box-shadow: 7px 8px 21px 0 #1E1E1E;
      max-width: 92%;
      max-height: 92%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
    .video-control{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;

      button{
        transition: all .3s ease-in;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 60px;
        padding: 9px 7px;
        border: 0;
        cursor: pointer;
        width: 50px;
        height: 50px;
        background: $color-white;
        &:hover {
          background: darken($color-white, 10%);
        }
        &:active {
          background: darken($color-white, 10%);
        }
      }
    }
    .arrow-container {
      height: 100%;
      width: 30%;
      position: absolute;

      .relative-container {
        position: relative;
        width: 100%;
        height: 100%;
        button{
          transition: all .3s ease-in;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 60px;
          width: 42px;
          height: 42px;
          padding: 9px 7px;
          border: 0;
          cursor: pointer;
          display: none;
          opacity: 0;
          background: $color-dark-disabled;
          &:hover {
            background: $color-dark-secondary;
          }
          &:active {
            background: $color-dark-primary;
          }
        }
      }
      &:hover {
        button {
          display: block;
          opacity: 1;
        }
      }
      &.prev-arrow-container {
        left: 0;
        z-index: 99;
        button {
          left: 15%;
        }
      }
      &.next-arrow-container {
        right: 0;
        button {
          right: 15%;
        }
      }
      &.is-video {
        pointer-events: none;
        button {
          display: block;
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    .video-poster-button-container{
      position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .relative-content-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.cms-asset-form {
  padding: ($element-baseline-grid) ($element-baseline-grid * 3);
}