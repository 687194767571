@import "~styles/variables";

:export {
  backCancelButton: $color-dark-extreme2;
  backSaveButton: $color-accent-2;
}

.scene-editor-modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 1999;
  .scene-editor-modal {
    position: absolute;
    width: 406px;
    background: white;
    left: 20%;
    top: 20%;
    box-shadow: 1px 2px 13px -1px;
    border-radius: 4px;
    z-index: 1;
    min-height: 54%;
    height: 75%;
    .modal-relative-container {
      position: relative;
      width: 100%;
      height: 100%;
      .header-container {
        background: $background-light-3;
        color: $background-dark-1;
        padding: 0 ($element-baseline-grid * 3);
        //height: $sidebar-header-height-small; /*($element-baseline-grid * 8)*/
        margin: 0 !important;
        position: absolute;
        height: $header-height;
        width: 100%;
        box-sizing: border-box;
        cursor: move;
        h1 {
          padding: 0;
          margin: 5px 0 0 0;
        }
        h2 {
          margin: 0;
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 87%;
          position: absolute;
          bottom: ($element-baseline-grid * 2.5);
        }
      }
      .form-container {
        position: absolute;
        top: $header-height;
        overflow: scroll;
        height: calc(100% - #{$header-height*2 + 30px});
        width: 100%;
        .asset-list-editor{
          img {
            max-height: 224px;
          }
        }
        .feedback-button-container{
          width: 100%;
          text-align: center;
          clear: both;
          padding-top: 10px;
        }
        .language-select{
          margin-left: 28px;
          margin-top: 10px
        }
      }
      .modal-actions-container {
        position: absolute;
        bottom: 0;
        margin-top: 29px;
        padding: 10px 15px;
        text-align: right;
        border-top: 1px solid #d6d6d6;
        width: 100%;
        box-sizing: border-box;
        height: $header-height;
      }
    }
  }
  &.open {
    display: block;
  }
  .modal-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.50)
  }
}