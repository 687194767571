@import "~styles/variables";
@import "~styles/mixins";

.sidebar-styles-actionbar{
    padding-top: ($element-baseline-grid * 1);
      
  .left-item {
    padding-left: 0;
  }

  .right-item {
    text-align: right;
    padding-right: 0;
  }
}

.tiles-content-area {
  /*overflow-y: auto;*/
}
.tile-container {
  margin: ($element-baseline-grid * 3) ($element-baseline-grid * 3);
  /*padding: $element-baseline-grid;*/
  border: 1px solid $background-light-3;
  outline: 2px solid $background-light-3;
  position: relative;

  .tile-header-row {
    text-align: center;
    min-height: 80px;
    line-height: 80px;

  }

  .tile-header-text {
    color: $color-white;
    @include cms-sub-header2-text;
  }
  .tile-color-row {
    height: 40px;
    text-wrap: normal;
  }

  .tile-action-container {
    /*background: $color-accent-2;*/
    opacity: 0;
    @include background-opacity($color-accent-2, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    /*border: 1px solid red;*/

    .tile-action-row {
      @include cms-sub-header1-text;
      color: $color-white;
      margin: 0;
      padding: ($type-baseline-grid * 2);
      /*line-height: 24px;*/

      .material-icons {
        vertical-align: middle;
        padding-right: 12px;
      }
      .tile-action-text {
        vertical-align: middle;
      }
    }
  }

  .tile-action-container:hover {
    opacity: 1;
    @include background-opacity($color-accent-2, .80);
  }
}

.save-style-container {
  padding: ($element-baseline-grid * 3) ($element-baseline-grid * 3);
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  min-height: 80px;
  .style-name-label {
    @include cms-body1-text;
  }
}

.style-header-container {
  background: $background-dark-2;
}

.style-header-row {
  color: $background-light-1;
}

.style-actions-container {
  padding: ($element-baseline-grid ) 0;

  .style-action-secondary {
    background: $color-light-disabled !important;
  }


}

.style-selector-header {
  /*z-index: 21000;*/
  border-bottom: 1px solid $color-dark-primary;
  overflow-y: hidden;
}

.style-selector-header-text {
  @include cms-sub-header2-text;
  color: $color-dark-primary;
  line-height: 48px;
}

.style-selector-header-menu {
  /*z-index: 20000;*/
}

.style-actions-row {
  background: $background-dark-1;
  width: 100%;
}

.style-selector-list{
    padding: ($element-baseline-grid * 3) ($element-baseline-grid * 3);
    width:320px;
    -webkit-box-shadow: 10px 10px 22px -6px rgba(0,0,0,0.65);
    -moz-box-shadow: 10px 10px 22px -6px rgba(0,0,0,0.65);
    box-shadow: 10px 10px 22px -6px rgba(0,0,0,0.65);
    height: 80%;
    z-index: 100;
}
