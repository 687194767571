@import "~styles/variables";

:export {
  sidebarExpandedWidth: $main-sidebar-expanded-width;
  sidebarCollapsedWidth: $main-sidebar-collapsed-width;
  auxSidebarPlusSidebarExpandedWidth: $main-sidebar-expanded-width + $sidebar-def-width;
  auxSidebarPlusSidebarCollapsedWidth: $main-sidebar-collapsed-width + $sidebar-def-width;
}

.preview-container {

  .display-container {

    height: calc(100% - #{$footer-height});
    position: relative;
    width: 100%;
    overflow-x: hidden;

    iframe {
      transform-origin: top left;
      -webkit-transform-origin: top left;
      -o-transform-origin: top left;
      -ms-transform-origin: top left;
      -moz-transform-origin: top left;
      margin: 0;
      padding: 0;
      background-color: #fff;
      border-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .desktop {
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
      position: relative;
    }

    .desktop iframe {
      width: 1600px;
      height: 992px;
      transform-origin: top left;
      -webkit-transform-origin: top left;
      -o-transform-origin: top left;
      -ms-transform-origin: top left;
      -moz-transform-origin: top left;
    }

    .tablet {
      width: 100%;
      height: 100%;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: relative;
    }
    .tablet iframe {
      width: 768px;
      height: 1024px;
      overflow-y: hidden;
      transform-origin: top left;
      -webkit-transform-origin: top left;
      -o-transform-origin: top left;
      -ms-transform-origin: top left;
      -moz-transform-origin: top left;
    }

    .mobile {
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 20;
      position: relative;
    }
    .mobile iframe {
      width: 320px;
      height: 480px;
      overflow-y: hidden;
      transform-origin: top left;
      -webkit-transform-origin: top left;
      -o-transform-origin: top left;
      -ms-transform-origin: top left;
      -moz-transform-origin: top left;
    }
  }

  .footer-container {
    padding-left: calc( (100% - #{$sidebar-def-width}) / 2 - 6%);
    .display-control {
      float: left;
      margin-right: 2%;
      a {
        color: $color-light-primary;
      }

      .material-icons.md-36 {
        font-size: 36px;
      }
      .material-icons.md-light {
        color: rgba(255, 255, 255, 1);
      }
      .material-icons.md-light.md-inactive {
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}
