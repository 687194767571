@import "~styles/variables";

.image-cropper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
  display: none;
  &.open{
    display: block;
  }
  .content-container{
    position: relative;
    left: 0;
    .container-body {
      position: absolute;
      width: 55%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      height: calc(55% - 64px) !important;
      bottom: auto !important;
      .relative-subcontainer {
        position: relative;
        width: 100%;
        height: 100%;
      }
      //.ReactCrop{
      //  position: relative;
      //  width: 100%;
      //  height: 100%;
      //  img{
      //    max-height: 100%;
      //    position: absolute;
      //    top: 50%;
      //    left: 50%;
      //    transform: translate(-50%, -50%);
      //  }
      //}
      .ReactCrop {
        //height: 100%;
        img {
          max-height: 100%;
        }
      }
    }
    .selected-bar {
      margin-left: ($element-baseline-grid * 1.5);
      .select-button-container {
        float: right;
        padding-top: 13px;
      }
    }
  }
}