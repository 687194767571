body {
  font-family: $body-font;
}

h1 {
  @include cms-title-text;
}

h2 {
  @include cms-sub-header2-text;
}

h3 {
  @include cms-sub-header1-text;
}

h4 {
  @include cms-body4-text;
}

h5 {
  @include cms-body3-text;
}

h6 {
  @include cms-body2-text;
}

.caption {
  @include cms-caption-text;
}