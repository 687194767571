@import "~styles/variables";
@import "~styles/mixins";

:export {
  contentContainerBack: $background-light-2;
  captionHeight: $card-caption-height;
  minWidthCaption: $card-browser-breakpoint-caption;
  minCardMargin: $card-browser-minimum-card-margin;
}

.collection-item-editor {
  position: absolute;
  width: 100%;
  height: 100%;
  .content-container {
    .header-container {
      box-shadow: rgba(150, 150, 150, .8) 0 2px 6px !important;
      .header-title {
        width: calc(100% - 650px);
      }
      .header-content {
        width: 550px;
      }
      .language-select {
        margin-top: 10px;
        margin-right: 15px;
      }
    }
  }

  // .slideshow-sidebar {
  //   width: $main-sidebar-expanded-width
  // }

  .collection-item-editor-container {
    // width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    .tab-form-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;
      .tab-column{
        position: relative;
        float: left;
        .list-group-header{
         h2{
           font-size: 12px;
           color: black;
           font-weight: bold;
           font-family: Roboto, sans-serif;
         }
        }
      }
      .column-right-border{
        border-right: 1px solid #ccc;
      }
      .column-general{
        width: 31%;
        height: 100%;
        overflow: scroll;
        .cms-list{
          margin-bottom: 30px;
        }
      }
      .column-asset{
        overflow: scroll;
        width: 31%;
        height: 100%;
      }
      .column-description{
        overflow: scroll;
        width: 37%;
        height: 100%;
      }
      .column-settings{
        overflow: scroll;
        width: 20%;
        height: 100%;
      }
      .column-answer{
        overflow: scroll;
        width: 39.50%;
        height: 100%;
      }

    }
  }

  .collection-item-editor-topbar {
    .scene-navigation-component {
      float: right;
      color: $color-dark-primary;
      .slide-numbers-container {
        float: left;
        padding-top: 26px;
      }
      .empty-arrow {
        width: 60px;
        float: right;
      }
    }
    .change-layout-container {
      float: right;
    }
  }
}
