@import "~styles/variables";
@import "~styles/mixins";

.sidebar {
  .forms-container{
    padding: $element-baseline-grid 0;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    background: white;
    input, textarea, .ql-editor {
      //font-size: 13px !important;
    }
    .cms-list{
      width: 100%;
      float: left;
      box-sizing: border-box;
      overflow: scroll;
      height: 100%;
      .list-group-header{
        padding: $element-baseline-grid ($element-baseline-grid*3);
        height: $element-baseline-grid * 2;
        h2{
          color: $color-dark-primary;
          font-size:16px;
          font-weight: 600;
        }
      }
    }
  }

  .sidebar-subheader-content{
    padding: ($element-baseline-grid * 2) ($element-baseline-grid * 3);
    .subheader-icon{
      width: ($element-baseline-grid * 5);
      background: $background-dark-1;
      border-radius: 30px;
      height: ($element-baseline-grid * 3);
      text-align: center;
      padding: $element-baseline-grid 0;
      float: left;
    }
    .subheader-meta{
      float: left;
      width: 255px;
      margin-left: ($element-baseline-grid * 2);
      margin-top: 14px;
      font-size: 13px;
      .filename{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span{
        @include cms-body1-text{
          margin-right: 10px;
          color: $color-dark-secondary;
        }

      }
    }
  }
}