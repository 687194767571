.cms-card-browser {
  overflow-y: scroll;
  height: 100%;
  width:100%;
  margin: 0;
  position: absolute;
  .items-container {
    a:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
    .neon-resizable-card{
      float: left;
    }
  }
  &:focus {
    outline: none;
  }
  .progress-container{
    width: 100vw;
    height: 100vh;
    .progress-indicator{
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}